import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import resetSearchCommentResults from "../../actions/document/resetSearchCommentResults";
import resetCommentFilters from "../../actions/document/resetCommentFilters";
import resetCommentsForMe from "../../actions/document/resetCommentsForMe";
import getCommentsForMe from "../../actions/document/getCommentsForMe";
import searchComments from "../../actions/document/searchComments";

import CloseSVG from "../../images/icons/svg/close-grey.svg";
import filterIcon from "../../images/icons/svg/sort-filter-icon.svg";
import filterIconActive from "../../images/icons/svg/sort-filter-icon-active.svg";
import cancelFilterIcon from "../../images/icons/svg/cancel-filter-icon.svg";
import FilterPill from "../Common/FilterPill";

import { UikInput, UikButton } from "@uik";

import styles from "./ConversationPanelSearch.module.scss";

export default (props) => {
  const {
    versionId,
    updateConversationList,
    commentorUsers,
    docId,
    readOnly,
    searchValue,
    setSearchValue,
    forMeIsActive,
    showLoader,
  } = props;

  const dispatch = useDispatch();

  const boundActionCreators = bindActionCreators(
    {
      resetSearchCommentResults,
      resetCommentFilters,
      resetCommentsForMe,
      getCommentsForMe,
      searchComments,
    },
    dispatch
  );

  const searchResults = useSelector((state) => {
    if (state.commentSearchResults) {
      return state.commentSearchResults.searchResults;
    }
  });

  const filters = useSelector((state) => {
    return state.commentFilters;
  });

  const [activeFilters, setActiveFilters] = useState([]);

  const [filtersActive, setFiltersActive] = useState(false);

  const [forMeFilterActive, setForMeFilterActive] = useState(false);

  useEffect(() => {
    updateConversationList(searchResults);
  }, [searchResults]);

  const onSearchChange = (value) => {
    setSearchValue(value);
    showLoader(true);
    if (filters) {
      boundActionCreators
        .searchComments(
          versionId ? versionId : docId,
          value,
          filters.commentor,
          filters.date,
          filters.sort
        )
        .then(() => {
          showLoader(false);
        });
    } else {
      boundActionCreators
        .searchComments(versionId ? versionId : docId, value)
        .then(() => {
          showLoader(false);
        });
    }
  };

  const toggleFilters = (restoreDefault) => {
    if (restoreDefault) {
      boundActionCreators.resetCommentFilters();
      boundActionCreators.resetCommentsForMe();
      boundActionCreators
        .searchComments(versionId ? versionId : docId, searchValue)
        .then(() => {
          showLoader(false);
        });
    } else {
      boundActionCreators.resetCommentFilters();
      boundActionCreators.resetCommentsForMe();
      boundActionCreators
        .searchComments(versionId ? versionId : docId, searchValue)
        .then(() => {
          showLoader(false);
        });
      setActiveFilters([]);
      setForMeFilterActive(false);
      if (filtersActive) {
        setFiltersActive(false);
      } else {
        setFiltersActive(true);
      }
    }
  };

  const deactivateFilter = (filterName) => {
    let activeFiltersUpdated = activeFilters.map((filter) => {
      if (filter !== filterName) {
        return filter;
      }
    });
    setActiveFilters(activeFiltersUpdated);
  };

  const toggleForMe = (dateRange) => {
    if (forMeFilterActive) {
      forMeIsActive(false);
      setForMeFilterActive(false);
      boundActionCreators.resetCommentFilters();
      boundActionCreators.resetSearchCommentResults();
      boundActionCreators.resetCommentsForMe();
      setActiveFilters([]);
    } else {
      showLoader(true);
      forMeIsActive(true);
      boundActionCreators
        .getCommentsForMe(versionId ? versionId : docId, dateRange)
        .then(() => {
          showLoader(false);
        });
      setForMeFilterActive(true);
      boundActionCreators.resetSearchCommentResults();
      boundActionCreators.resetCommentFilters();
    }
  };

  const executeSearch = (commentor, date, sort, filterLastChanged) => {
    showLoader(true);
    if (filters && filterLastChanged === "date") {
      boundActionCreators
        .searchComments(
          versionId ? versionId : docId,
          searchValue,
          filters.commentor,
          date,
          filters.sort
        )
        .then(() => {
          showLoader(false);
        });
    } else if (filters && filterLastChanged === "commentor") {
      showLoader(true);
      boundActionCreators
        .searchComments(
          versionId ? versionId : docId,
          searchValue,
          commentor,
          filters.date,
          filters.sort
        )
        .then(() => {
          showLoader(false);
        });
    } else if (!filters) {
      boundActionCreators
        .searchComments(
          versionId ? versionId : docId,
          searchValue,
          commentor,
          date,
          sort
        )
        .then(() => {
          showLoader(false);
        });
    }
  };

  const settingFilter = (filter) => {
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    let currentFilters = activeFilters;
    currentFilters.push(filter);
    setActiveFilters(currentFilters.filter(onlyUnique));
  };

  const sortResults = (sortOption) => {
    if (!searchResults) {
      boundActionCreators
        .searchComments(versionId ? versionId : docId, "", null, null, true)
        .then((results) => {
          if (sortOption === "NewestToOldest") {
            const sortedResults = results.sort(function (a, b) {
              return new Date(b.created_at) - new Date(a.created_at);
            });
            updateConversationList(sortedResults, sortOption);
          } else if (sortOption === "OldestToNewest") {
            const sortedResults = results.sort(function (a, b) {
              return new Date(a.created_at) - new Date(b.created_at);
            });
            updateConversationList(sortedResults, sortOption);
          } else if (sortOption === "Status") {
            updateConversationList(results, sortOption);
          } else if (sortOption === "Page") {
            updateConversationList(results, sortOption);
          }
        });
    } else {
      if (sortOption === "NewestToOldest") {
        const sortedResults = searchResults.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        updateConversationList(sortedResults, sortOption);
      } else if (sortOption === "OldestToNewest") {
        const sortedResults = searchResults.sort(function (a, b) {
          return new Date(a.created_at) - new Date(b.created_at);
        });
        updateConversationList(sortedResults, sortOption);
      } else if (sortOption === "Status") {
        updateConversationList(searchResults, sortOption);
      } else if (sortOption === "Page") {
        updateConversationList(searchResults, sortOption);
      }
    }
  };

  return (
    <div className={styles.searchPanelContainer}>
      <div
        className={
          searchValue.length === 0
            ? styles.searchInputContainer
            : styles.searchInputContainerActive
        }
      >
        <div className={styles.InputContainer}>
          <UikInput
            id="comment-search"
            readonly={forMeFilterActive}
            placeholder={
              forMeFilterActive ? "Search Disabled" : "Search Comments"
            }
            className={
              searchValue.length === 0
                ? styles.searchInputFieldEmpty
                : styles.searchInputFieldActive
            }
            onChange={
              forMeFilterActive ? null : (e) => onSearchChange(e.target.value)
            }
            value={forMeFilterActive ? "" : searchValue}
          ></UikInput>
        </div>
        <span
          className={
            searchValue.length === 0
              ? styles.hideCloseComment
              : styles.showCloseComment
          }
          onClick={() => {
            setSearchValue("");
            boundActionCreators.resetSearchCommentResults();
            boundActionCreators.resetCommentFilters();
          }}
        >
          <img src={CloseSVG} />
        </span>
        <div
          id="comment-filter-toggle"
          className={styles.filterIconContainer}
          onClick={() => {
            toggleFilters();
          }}
        >
          <img
            className={
              searchResults || filtersActive
                ? styles.filterIconActive
                : styles.filterIcon
            }
            src={searchResults || filtersActive ? filterIconActive : filterIcon}
            onMouseOver={(e) =>
              filtersActive
                ? (e.currentTarget.src = cancelFilterIcon)
                : filterIcon
            }
            onMouseOut={(e) =>
              searchResults || filtersActive
                ? (e.currentTarget.src = filterIconActive)
                : filterIcon
            }
          />
        </div>
      </div>
      {(searchResults || filtersActive) && (
        <div className={styles.filterPillsContainer}>
          <FilterPill
            id="comment-filter-forme"
            filterName="For Me"
            list={false}
            executeSearch={executeSearch}
            sortResults={sortResults}
            settingFilter={settingFilter}
            activeFilters={activeFilters}
            toggleForMe={toggleForMe}
            forMeFilterActive={forMeFilterActive}
          />
          <FilterPill
            id="comment-filter-date"
            filterName="Date"
            list={true}
            executeSearch={executeSearch}
            sortResults={sortResults}
            settingFilter={settingFilter}
            activeFilters={activeFilters}
            toggleForMe={toggleForMe}
            forMeFilterActive={forMeFilterActive}
            toggleFilters={toggleFilters}
            deactivateFilter={deactivateFilter}
          />
          <FilterPill
            id="comment-filter-people"
            filterName="People"
            list={true}
            commentorUsers={commentorUsers}
            executeSearch={executeSearch}
            sortResults={sortResults}
            settingFilter={settingFilter}
            activeFilters={activeFilters}
            toggleForMe={toggleForMe}
            forMeFilterActive={forMeFilterActive}
            toggleFilters={toggleFilters}
            deactivateFilter={deactivateFilter}
          />
          <FilterPill
            id="comment-filter-sort"
            filterName="Sort"
            list={true}
            executeSearch={executeSearch}
            sortResults={sortResults}
            settingFilter={settingFilter}
            activeFilters={activeFilters}
            toggleForMe={toggleForMe}
            forMeFilterActive={forMeFilterActive}
          />
        </div>
      )}
    </div>
  );
};
