import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Line } from "rc-progress";
import moment from "moment";
import approvalIconGreen from " ../../images/icons/svg/approved-green.svg";
import approvalIcon from " ../../images/icons/svg/approved-grey.svg";
import styles from "./ApprovalsSection.module.scss";
import security from "../../services/Security";

/* UI Kit */
import { UikDropdown, UikDropdownItem, Uikon } from "@uik";

export default (props) => {
  const { documentDetails, readOnly, versionId, versionNumber, approval } =
    props;

  const [imgSrc, setImgSrc] = useState(approvalIcon);
  const role = security.getUserRole();
  let approversNotObservers = documentDetails.total_approvals;

  const currentUserIsObserver = () => {
    let isObserver = false;
    if (approval && approval.observer) {
      isObserver = true;
    }
    return isObserver;
  };

  const canEditApprovers = () => {
    let canEdit = false;
    if (role === "admin" || role === "author") {
      canEdit = true;
    }
    return canEdit;
  };

  const mouseEnter = () => {
    if (canEditApprovers()) {
      setImgSrc(approvalIconGreen);
    }
  };

  const mouseLeave = () => {
    setImgSrc(approvalIcon);
  };

  const getPercentApproved = () => {
    const percentApproved =
      (1 - documentDetails.pending_approvals / approversNotObservers) * 100;
    return percentApproved;
  };

  const renderReferenceDocumentsUploaderProgress = (approversNotObservers) => {
    let progress;
    if (approversNotObservers === 0) {
      progress = 0;
    } else if (documentDetails.approved) {
      progress = 100;
    } else {
      progress = getPercentApproved();
    }
    return (
      <div
        className={
          !approversNotObservers
            ? styles.progressBarNoApprovers
            : styles.progressBar
        }
      >
        <div>
          <Line
            className={styles.uploadProgress}
            percent={progress}
            strokeWidth="2"
            strokeColor="#1b8c96"
            strokeLinecap="round"
            trailColor="#eaedf3"
            trailWidth="2"
          />
        </div>
      </div>
    );
  };

  const renderProgressCaption = (approversNotObservers) => {
    let progressCaption;
    let captionStyle;
    if (!documentDetails.everyone_approved && approversNotObservers) {
      if (approval && approval.document) {
        if (approval.document.everyone_approved) {
          captionStyle = styles.progressCaptionComplete;
          progressCaption = `Completed on ${moment(
            documentDetails.approvals_date
          ).format("MMM D, YYYY")}`;
        } else {
          progressCaption = `${documentDetails.pending_approvals}/${approversNotObservers} Remaining`;
          captionStyle = styles.progressCaption;
        }
      } else {
        progressCaption = `${documentDetails.pending_approvals}/${approversNotObservers} Remaining`;
        captionStyle = styles.progressCaption;
      }
    } else if (documentDetails.everyone_approved && approversNotObservers) {
      if (
        approval &&
        approval.document &&
        !approval.document.everyone_approved
      ) {
        progressCaption = `${documentDetails.pending_approvals}/${approversNotObservers} Remaining`;
        captionStyle = styles.progressCaption;
      } else {
        captionStyle = styles.progressCaptionComplete;
        progressCaption = `Completed on ${moment(
          documentDetails.approvals_date
        ).format("MMM D, YYYY")}`;
      }
    } else if (!approversNotObservers) {
      if ((approval && approval.observer) || role === "viewer") {
        captionStyle = styles.progressCaptionReadonly;
      } else {
        captionStyle = styles.progressCaption;
      }
      progressCaption = "No Approvers";
    }
    return <div className={captionStyle}>{progressCaption}</div>;
  };

  return (
    <div className={styles.approvalsSection} id="approvals">
      <div className={styles.sectionContainer}>
        <Link
          className={
            canEditApprovers()
              ? `${styles.sidePanelOptions} ${
                  !documentDetails.approved &&
                  approval &&
                  approval.id &&
                  !approval.observer &&
                  styles.sidePanelOptionsApprovals
                }`
              : `${styles.viewerSidePanelOptions} ${
                  !documentDetails.approved &&
                  approval &&
                  approval.id &&
                  !approval.observer &&
                  styles.sidePanelOptionsApprovals
                }`
          }
          to={{
            pathname: canEditApprovers()
              ? `/documents/${documentDetails.id}/approvers`
              : "#",
            state: documentDetails.approved
              ? {
                  readOnly: readOnly,
                  versionId: versionId,
                  versionNumber: versionNumber,
                  originalDocumentId: documentDetails.id,
                }
              : {
                  readOnly: readOnly,
                  libraryReadOnly: !documentDetails.updatable_library,
                  versionId: versionId,
                  versionNumber: versionNumber,
                  originalDocumentId: documentDetails.id,
                },
          }}
          onMouseOver={mouseEnter}
          onMouseOut={mouseLeave}
        >
          {documentDetails.approved && (
            <div
              className={
                canEditApprovers()
                  ? styles.approvalsLink
                  : styles.viewerApprovalsLink
              }
            >
              <div className={styles.approvalsCard}>
                <span className={styles.approvals}>
                  <span>
                    <img id={"approval"} className={styles.icon} src={imgSrc} />
                  </span>
                  Approvals
                </span>
                {renderReferenceDocumentsUploaderProgress(
                  approversNotObservers
                )}
                {approversNotObservers !== 0 && (
                  <span className={styles.approvalsRemaining}>
                    {`${Math.floor(getPercentApproved())}%`}
                  </span>
                )}
              </div>
              <div className="clear" />
            </div>
          )}
          {!documentDetails.approved && (
            <div
              className={
                canEditApprovers()
                  ? styles.approvalsLink
                  : styles.viewerApprovalsLink
              }
            >
              <div
                className={
                  (approval && approval.observer) || role === "viewer"
                    ? styles.approvalCardReadOnly
                    : styles.approvalsCard
                }
              >
                <span className={styles.approvals}>
                  <span>
                    <img id={"approval"} className={styles.icon} src={imgSrc} />
                  </span>
                  Approvals
                </span>
                {renderReferenceDocumentsUploaderProgress(
                  approversNotObservers
                )}
                {approversNotObservers !== 0 && (
                  <span className={styles.approvalsRemaining}>
                    {`${Math.floor(getPercentApproved())}%`}
                  </span>
                )}
                {approversNotObservers === 0 && canEditApprovers() && (
                  <span className={styles.editLink}>Edit</span>
                )}
              </div>
              <div className="clear" />
            </div>
          )}
        </Link>
        {renderProgressCaption(approversNotObservers)}
      </div>
      {(approversNotObservers === 0 || readOnly || currentUserIsObserver()) && (
        <div className={styles.divider}></div>
      )}
    </div>
  );
};
