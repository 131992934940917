import React, { useState, useEffect } from "react";
import CloseSVG from "../../../../images/icons/svg/close.svg";
import { useDispatch, useSelector } from "react-redux";
import ClaimCard from "./ClaimCard";
import moment from "moment";
import moreSVG from "../../../../images/icons/svg/more.svg";
import httpClient from "../../../../lib/HttpClient";
import notificationService from "../../../../services/Notifications";
import Modal from "../../../Common/Modal";
import leftarrowSVG from "../../../../images/icons/svg/left-arrow.svg";

import styles from "./Claims.module.scss";
import SingleClaimCard from "./SingleClaimCard";
import LinkClaimDiff from "./LinkClaimDiff.js";
import ClaimsSearch from "./ClaimsSearch";
import claimsEmptyIcon from "../../../../images/icons/svg/claims-empty-icon.svg";

import fetchClaims from "../../../../actions/document/fetchClaims";
import fetchDocumentClaims from "../../../../actions/document/fetchDocumentClaims";

/* UI Kit */
import { UikDropdown, UikDropdownItem, UikDivider, UikButton } from "@uik";

export default (props) => {
  const {
    closePanel,
    selectedClaim,
    setSelectedClaim,
    onAnnotationDeselect,
    viewingCurrentVersion,
  } = props;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);

  const actionsDropDown = ({ onClick }) => {
    return (
      <span onClick={onClick}>
        <img className={styles.moreIcon} src={moreSVG} />
      </span>
    );
  };

  const onClose = () => {
    closePanel();
    onAnnotationDeselect();
  };

  const claims = useSelector((state) => {
    return state.claims;
  });

  const documentClaims = useSelector((state) => {
    return state.documentClaims;
  });

  const dispatch = useDispatch();

  const renderNoClaimsEmptyState = () => {
    return (
      <div className={styles.emptyState}>
        <div className={styles.linkIcon}>
          <img src={claimsEmptyIcon} />
        </div>
        <div className={styles.text}>There are no product claims.</div>
        <div className={styles.text}>
          To create one, highlight some text and select the product claims icon.
        </div>
      </div>
    );
  };

  const renderNoSearchResultsEmptyState = () => {
    return (
      <div className={styles.noSearchResultsEmptyStateContainer}>
        <div className={styles.titleText}>We could not find any results</div>
        <div className={styles.text}>
          Try checking your spelling or entering anouther input
        </div>
      </div>
    );
  };

  const renderSelectedClaim = () => {
    if (selectedClaim.linked) {
      return (
        <div>
          <div className={styles.diffClaimSuggester}>
            {" "}
            Product claim suggested by {selectedClaim.created_by_user_name}
            <div className={styles.dotSeparator}>&#9679;</div>
            <div className={styles.date}>
              <div className={styles.fullDateTimeContainer}>
                <div className={styles.fullDateTime}>
                  {moment(selectedClaim.created_at).format("MMM D")}
                </div>
                <div className={styles.bottomArrow} />
              </div>
            </div>
          </div>
          <div className={styles.diffDividerContainer} />
          <SingleClaimCard claim={selectedClaim} />
          <div className={styles.diffDividerContainer} />
          <LinkClaimDiff claim={selectedClaim} />
        </div>
      );
    } else {
      return (
        <div>
          <SingleClaimCard claim={selectedClaim} />
        </div>
      );
    }
  };

  const deleteClaim = () => {
    let url;
    if (selectedClaim.linked) {
      url = `/document_claims/${selectedClaim.id}.json`;
    } else {
      url = `/claims/${selectedClaim.id}.json`;
    }

    httpClient
      .delete(url)
      .then(() => {
        const webViewerEl = document.getElementById("webViewer");
        const annotManager =
          window.WebViewer.getInstance(
            webViewerEl
          ).docViewer.getAnnotationManager();

        annotManager.deselectAllAnnotations();

        const annot = annotManager.getAnnotationById(
          selectedClaim.xfdf_highlight_id
        );

        annotManager.deleteAnnotation(annot);
        annotManager.hideAnnotation(annot);

        notificationService.addNotification(
          "Product Claim deleted",
          "Product Claim deleted",
          "success"
        );

        dispatch(fetchClaims(selectedClaim.document_id));
        dispatch(fetchDocumentClaims(selectedClaim.document_id));
        closePanel();
      })
      .catch((error) => {
        if (error.response.status === 403) {
          notificationService.addNotification(
            "You are not permitted to delete product claims created by others",
            "You are not permitted to delete product claims created by others",
            "warning"
          );
        } else if (error.response.status === 422) {
          notificationService.addNotification(
            "Product claims linked in other content cannot be deleted",
            "Product claims linked in other content cannot be deleted",
            "warning"
          );
        }
      });
  };

  const renderClaims = () => {
    let claimsObj = {};

    if (!searchResults) {
      claims.length > 0 &&
        claims.forEach((claim) => {
          if (!claimsObj[claim.page.page_number]) {
            claimsObj[claim.page.page_number] = [];
          }
          claimsObj[claim.page.page_number].push(claim);
        });

      documentClaims.length > 0 &&
        documentClaims.forEach((documentClaim) => {
          if (!claimsObj[documentClaim.page.page_number]) {
            claimsObj[documentClaim.page.page_number] = [];
          }
          claimsObj[documentClaim.page.page_number].push(documentClaim);
        });
    } else {
      searchResults.forEach((claim) => {
        if (!claimsObj[claim.page.page_number]) {
          claimsObj[claim.page.page_number] = [];
        }
        claimsObj[claim.page.page_number].push(claim);
      });
    }

    return Object.entries(claimsObj).map(([key, claims], index) => (
      <div key={index} className={styles.page}>
        <div className={styles.title}>{`page ${key}`}</div>
        <div className={styles.dividerContainer} />
        {searchResults
          ? searchResults.map((claim) => (
              <div>
                <ClaimCard
                  claim={claim}
                  setSelectedClaim={setSelectedClaim}
                  inCommentPanel={false}
                  searchTerm={searchTerm}
                />
                <div className={styles.dividerContainer} />
              </div>
            ))
          : claims.map((claim) => (
              <div>
                <ClaimCard
                  claim={claim}
                  setSelectedClaim={setSelectedClaim}
                  inCommentPanel={false}
                />
                <div className={styles.dividerContainer} />
              </div>
            ))}
      </div>
    ));
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.text}>
          {selectedClaim && (
            <span
              className={styles.backArrow}
              onClick={() => setSelectedClaim(null)}
            >
              <img src={leftarrowSVG} />
            </span>
          )}
          Product Claims
        </div>
        <div className={styles.closeIcon}>
          {selectedClaim && viewingCurrentVersion && (
            <div className={styles.conversationActionsDropDownContainer}>
              <UikDropdown
                DisplayComponent={actionsDropDown}
                position="bottomRight"
              >
                <UikDropdownItem onClick={() => setShowDeleteModal(true)}>
                  {selectedClaim.linked
                    ? "Delete Suggested Product Claim"
                    : "Delete"}
                </UikDropdownItem>
              </UikDropdown>
            </div>
          )}
          <img src={CloseSVG} onClick={onClose} />
        </div>
      </div>
      <div className={styles.body}>
        {!selectedClaim && claims.length !== 0 && (
          <ClaimsSearch
            setSearchResults={setSearchResults}
            setSearchTerm={setSearchTerm}
          />
        )}
        {!selectedClaim && renderClaims()}
        {selectedClaim && renderSelectedClaim()}
        {documentClaims.length === 0 &&
          claims.length === 0 &&
          !selectedClaim &&
          renderNoClaimsEmptyState()}
        {claims.length > 0 &&
          !selectedClaim &&
          searchResults &&
          searchResults.length === 0 &&
          renderNoSearchResultsEmptyState()}
      </div>
      <Modal
        hideModal={() => setShowDeleteModal(false)}
        visible={showDeleteModal}
        type="alert"
      >
        <div className="heading">Confirm Delete</div>
        <UikDivider />
        <div className="body">
          Are you sure you want to delete this product claim?
        </div>
        <div className="clear" />
        <div className="dividerContainer">
          <UikDivider />
        </div>
        <div className="buttons">
          <UikButton onClick={() => deleteClaim()} primary>
            Confirm
          </UikButton>
          <UikButton onClick={() => setShowDeleteModal(false)}>
            Cancel
          </UikButton>
        </div>
      </Modal>
    </div>
  );
};
