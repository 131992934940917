import React, { useEffect, useState } from "react";
import { Line } from "rc-progress";

import Close from "../../images/icons/svg/close.svg";
import linkIcon from "../../images/icons/svg/link-icon.svg";
import AIRobot from "../../images/gifs/ai-robot.gif";
import Button from "../Common/Buttons/ButtonPrimary";
import Confidence from "../Common/Confidence";
import Tooltip from "../Common/Tooltip";
import httpClient from "../../lib/HttpClient";

import styles from "./PaigeReferences.module.scss";

export default (props) => {
  const {
    onClose,
    library,
    linkExistingAnnotation,
    linkManually,
    referenceXfdfHighlightId,
  } = props;

  const [loading, setLoading] = useState(true);
  const [loadingStage, setLoadingStage] = useState(0);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [suggestedReferences, setSuggestedReferences] = useState(null);

  const loadingStages = [
    {
      text1: "Hold Tight!",
      text2: "Paige is searching for relevant references",
    },
    {
      text1: "Only a Few More Seconds",
      text2: "Paige is almost done finding references",
    },
  ];

  useEffect(() => {
    const caption = localStorage.getItem("currentAnnotationText");

    setLoading(true);
    setLoadingPercentage(0);
    setLoadingStage(0);

    httpClient
      .post("/paige/recommend_references.json", {
        document: caption,
        library_id: library.id,
      })
      .then((res) => {
        setSuggestedReferences(res.data);
      })
      .catch((err) => {
        setSuggestedReferences([]);
      });
  }, [referenceXfdfHighlightId]);

  useEffect(() => {
    //Fake Loading
    setTimeout(() => {
      setLoadingPercentage((prevPercentage) => {
        if (prevPercentage === 60) {
          setLoadingStage(1);
        } else if (prevPercentage === 100 && suggestedReferences) {
          setLoading(false);
        }
        if (prevPercentage !== 100) {
          return prevPercentage + 20;
        }
      });
    }, 1000);
  }, [loadingPercentage]);

  const renderSuggestedReferences = () =>
    suggestedReferences.map((ref) => (
      <div className={styles.reference}>
        <div
          className={
            ref.document.reference_document_label && ref.label
              ? styles.purplePill
              : styles.greyPill
          }
        >
          {ref.document.reference_document_label && ref.label && (
            <div className={styles.referenceDocumentLabel}>
              {ref.document.reference_document_label}
            </div>
          )}
          {ref.document.reference_document_label && ref.label ? (
            <div className={styles.referenceLabel}>| {ref.label}</div>
          ) : (
            "No Label"
          )}
          <div className={styles.fullLabelsContainer}>
            <div className={styles.fullLabels}>
              {`${
                ref.document.reference_document_label &&
                ref.label &&
                ref.document.reference_document_label
              } ${
                ref.document.reference_document_label && ref.label
                  ? `| ${ref.label}`
                  : "No Label"
              }`}
            </div>
            <div className={styles.bottomArrow} />
          </div>
        </div>
        <div className={styles.caption}>{ref.caption}</div>
        <div className={styles.linkReferenceConfidence}>
          <div
            className={styles.linkReference}
            onClick={() => linkExistingAnnotation(ref)}
          >
            Link to Annotation
          </div>
          <Confidence percentage={ref.confidence * 100} />
        </div>
      </div>
    ));

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>Link Reference</div>
          <div className={styles.closeIcon} onClick={onClose}>
            <img src={Close} />
          </div>
        </div>
        {loading ? (
          <div className={styles.loadingState}>
            <div className={styles.aiRobot}>
              <img src={AIRobot} />
            </div>
            <div className={styles.textContainer}>
              <div className={styles.text1}>
                {loadingStages[loadingStage].text1}
              </div>
              <div className={styles.text2}>
                {loadingStages[loadingStage].text2}
              </div>
            </div>
            <Line
              className={styles.progressBar}
              percent={loadingPercentage}
              strokeWidth="1"
              strokeColor="#1b8c96"
              strokeLinecap="round"
            />
          </div>
        ) : suggestedReferences && suggestedReferences.length > 0 ? (
          <React.Fragment>
            <div className={styles.suggestionsState}>
              <div className={styles.subheading}>
                <div className={styles.text}>
                  Suggested annotations by paige
                </div>
                <div className={styles.tooltip}>
                  <Tooltip type="paige-description">
                    <div className={styles.body}>
                      <div className={styles.question}>Who is Paige?</div>
                      <div className={styles.description}>
                        Paige is the Papercurve AI. She suggests annotations
                        based on your content library and previous annotations.
                      </div>
                      <div className={styles.link}>
                        <a
                          target="_blank"
                          href="https://help.papercurve.com/knowledge-base/paige-ai"
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className={styles.suggestionsContainer}>
                {suggestedReferences && renderSuggestedReferences()}
              </div>
            </div>
            <div className={styles.manualLinkingButton} onClick={linkManually}>
              <Button original text="Link Manually" />
            </div>
          </React.Fragment>
        ) : (
          suggestedReferences &&
          suggestedReferences.length === 0 && (
            <div className={styles.emptyState}>
              <div className={styles.linkIcon}>
                <img src={linkIcon} />
              </div>
              <div className={styles.text}>
                There are no suggested annotations.
              </div>
              <div className={styles.manualLinkingButton}>
                <Button original text="Link Manually" onClick={linkManually} />
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};
