import React from "react";
import { Container } from "react-grid-system";
import { Link } from "react-router-dom";
import httpClient from "../../lib/HttpClient";
import moment from "moment";
import Dropzone from "react-dropzone";
import axios from "../../lib/axios-client";
import { Line } from "rc-progress";

/* Assets */
import styles from "./References.module.scss";
import leftArrow from "../../images/icons/16px/back-arrow.svg";
import Warning from "../../images/icons/png/warning.svg";
import sortIcon from "../../images/icons/svg/filter-icon.svg";

import "react-datepicker/dist/react-datepicker.css";
import Button from "../Common/Buttons/ButtonPrimary";
import security from "../../services/Security";

/* UI Kit */
import { UikInput, Uikon, UikDropdown, UikDropdownItem } from "@uik";
import "@uik/styles.css";
import "../../font.scss";

/* Papercurve Components */
import Header from "../shared/Header/Header";
import DocumentThumbnail from "./DocumentThumbnail";
import notificationService from "../../services/Notifications";

/* Variables */

class References extends React.Component {
  constructor() {
    super();
    this.state = {
      referenceDocuments: [],
      failedUploads: [],
      searchField: false,
      library: { name: "" },
      documentFields: [],
      selectOptions: [],
      loaded: false,
      showUploadModal: false,
      referenceFiles: [],
      filters: this.getLocalStorageItem("filters") || {},
      filterTypes: this.getLocalStorageItem("filterTypes") || {},
      search: "",
      dateTypeFilter: this.getLocalStorageItem("dateTypeFilter") || "approved",
      startDate: this.getLocalStorageItem("startDate") || "",
      endDate: this.getLocalStorageItem("endDate") || "",
      page: this.getLocalStorageItem("page") || 1,
      total_pages: this.getLocalStorageItem("total_pages") || 1,
      sort: this.getLocalStorageItem("sort") || "alpha_asc",
      menuOpen: false,
      tags: [],
      referenceDocumentUploadedPercentage: null,
      referenceDocumentFilename: "",
      libraryId: null,
      userRole: security.getUserRole(),
    };
  }

  getLocalStorageItem = (key) => {
    let searchOptions = JSON.parse(localStorage.getItem("searchOptions")) || {};
    if (searchOptions[security.getUserId()]) {
      return searchOptions[security.getUserId()][key] || false;
    } else {
      return false;
    }
  };

  setLocalStorageItem = (key, value) => {
    let searchOptions = JSON.parse(localStorage.getItem("searchOptions")) || {};
    if (!searchOptions[security.getUserId()]) {
      searchOptions[security.getUserId()] = {};
    }
    searchOptions[security.getUserId()][key] = value;

    localStorage.setItem("searchOptions", JSON.stringify(searchOptions));
  };

  storeAllSearchOptions = () => {
    this.setLocalStorageItem("filters", this.state.filters);
    this.setLocalStorageItem("filterTypes", this.state.filterTypes);
    this.setLocalStorageItem("search", this.state.search);
    this.setLocalStorageItem("dateTypeFilter", this.state.dateTypeFilter);
    this.setLocalStorageItem("startDate", this.state.startDate);
    this.setLocalStorageItem("endDate", this.state.endDate);
    this.setLocalStorageItem("page", this.state.page);
    this.setLocalStorageItem("total_pages", this.state.total_pages);
    this.setLocalStorageItem("sort", this.state.sort);
    this.setLocalStorageItem("searchField", this.state.searchField);
  };

  componentWillMount = () => {
    const { id } = this.props.match.params;

    this.loadReferenceDocuments(id);
    this.loadDocumentFields();
    this.loadLibraries(id);
    this.loadTags();
    this.setState({ libraryId: id });
    if (!this.state.search === "") {
      this.setState({ searchField: true });
    } else {
      this.setState({ searchField: false });
    }

    document.addEventListener("scroll", this.trackScrolling);
  };

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById("documents_container");
    if (this.isBottom(wrappedElement)) {
      if (this.state.page < this.state.total_pages) {
        this.setState({ page: ++this.state.page }, () => {
          const { id } = this.props.match.params;
          this.reLoadReferenceDocuments(id);
        });
      }
    }
  };

  loadLibraries = (id) => {
    httpClient.get(`/libraries/${id}.json`).then((response) => {
      this.setState({
        library: response.data,
      });
    });
  };

  loadReferenceDocuments = (id) => {
    httpClient.get(`/references_library/${id}.json`).then((res) => {
      let referenceDocs = res.data.shared_references;
      referenceDocs.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      this.setState({ referenceDocuments: referenceDocs });
    });
  };

  reLoadReferenceDocuments = (id) => {
    const data = {
      page: this.state.page,
      filters: this.state.filters,
      filterTypes: this.state.filterTypes,
      date_type: this.state.dateTypeFilter,
      start_date: this.state.startDate,
      end_date: this.state.endDate,
      search: this.state.search,
      sort: this.state.sort,
      references: true,
    };

    httpClient
      .post(`/libraries/${id}/documents.json`, data)
      .then((response) => {
        let documents = this.state.referenceDocuments;
        if (this.state.page > 1) {
          documents.push(...response.data.documents);
        } else {
          documents = response.data.documents;
        }

        this.setState(
          {
            referenceDocuments: documents,
            total_pages: response.data.total_pages,
          },
          () => {
            //this.loadDocumentMetaData();
          }
        );
      });
  };

  loadDocumentFields = () => {
    httpClient.get("/document_fields.json").then((response) => {
      let documentFields = response.data;
      let selectOptions = {};

      documentFields.map((df) => {
        if (df.field_type === "select_options") {
          selectOptions[df.key] = df.document_field_select_options;
        }
      });

      documentFields = documentFields.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      this.setState({
        documentFields: documentFields,
        selectOptions: selectOptions,
      });
    });
  };

  loadDocumentMetaData = () => {
    const promises = [];
    const documents = this.state.documents;
    for (let i in documents) {
      const promise = httpClient
        .get(`/documents/${documents[i]["id"]}/metadata.json`)
        .then((response) => {
          let documentFieldValues = {};

          response.data.map((metadata, idx) => {
            documentFieldValues[metadata.document_field.key] = metadata.value;
          });
          documents[i]["metadata"] = response.data;
          documents[i]["fieldValues"] = documentFieldValues;
        });
      promises.push(promise);
    }
    Promise.all(promises).then((values) => {
      this.setState({ documents: documents, loaded: true });
    });
  };

  loadTags = () => {
    httpClient.get("/tags.json").then((response) => {
      const tags = [];
      response.data.map((option, index) => {
        tags.push({ value: option.id, label: option.name });
      });
      this.setState({ tags: tags });
    });
  };

  handleDocumentSearch = (e) => {
    e.preventDefault();

    this.setState({ search: e.target.value }, () => {
      this.filterDocuments();
    });
  };

  handleOpenSearch = () => {
    this.setState({ searchField: true });
  };

  handleCloseSearch = () => {
    this.setState({ searchField: false, search: "" }, () => {
      this.filterDocuments();
    });
  };

  filterDocuments = () => {
    const { id } = this.props.match.params;
    this.setState({ page: 1 }, () => {
      this.storeAllSearchOptions();
      this.reLoadReferenceDocuments(id);
    });
  };

  clearFilter = (key) => {
    let filters = this.state.filters;
    filters[key] = "";

    this.setState({ filters: filters });
  };

  onFilterChange = (value, key) => {
    let filters = this.state.filters;
    filters[key] = value;
    this.setState({ filters: filters });
  };

  onFilterDateChange = (value, key) => {
    let filters = this.state.filters;
    let filterTypes = this.state.filterTypes;
    filters[key] = value;
    if (!filterTypes[key]) {
      filterTypes[key] = "before";
    }
    this.setState({ filters: filters, filterTypes: filterTypes });
  };

  applyFilter = () => {
    this.filterDocuments();
  };

  onFilterTypeChange = (key, type) => {
    let filterTypes = this.state.filterTypes;
    filterTypes[key] = type;
    this.setState({ filterTypes: filterTypes });
  };

  onDateTypeFilterChange = (type) => {
    this.setState({ dateTypeFilter: type });
  };

  onChangeStartDate = (date) => {
    this.setState({ startDate: date });
  };

  onChangeEndDate = (date) => {
    this.setState({ endDate: date });
  };

  clearDates = () => {
    this.setState({ dateTypeFilter: "", startDate: "", endDate: "" }, () => {
      this.filterDocuments();
    });
  };

  setShowUploadModal = () => {
    this.setState({ showUploadModal: true });
  };

  clearDate = (key) => {
    let filters = this.state.filters;
    let filterTypes = this.state.filterTypes;
    filters[key] = "";

    filterTypes[key] = "";

    this.setState({ filters: filters, filterTypes: filterTypes });
  };

  setSort = (sort) => {
    this.setState({ sort: sort }, () => {
      this.filterDocuments();
    });
  };

  toggleMenuOpen = (isOpen) => {
    this.setState({ menuOpen: isOpen });
  };

  handleTagsChange = (e) => {};

  renderReferenceDocumentsUploaderProgress = (ref, idx) => {
    const refProgressPercentage = this.state["referenceFilePercentages" + idx];
    return (
      <div className={styles.attachmentContentUploading}>
        <div>
          <Line
            className={styles.uploadProgress}
            percent={refProgressPercentage}
            strokeWidth="2"
            strokeColor="#1b8c96"
            strokeLinecap="square"
            trailColor="white"
            trailWidth="2"
          />
        </div>
      </div>
    );
  };

  closeFailedReference = (fileIdx) => {
    let failedUploads = this.state.failedUploads;
    failedUploads.splice(fileIdx, 1);
    this.setState({ failedUploads });
  };

  onUploadReferenceDocument = (acceptedFiles) => {
    const token = security.getToken();

    this.setState({
      referenceFiles: this.state.referenceFiles.concat(acceptedFiles),
    });

    acceptedFiles.map((file, index) => {
      const pctIndex = this.state.referenceFiles.length + index;

      const postConfig = {
        headers: {
          Authorization: "Bearer " + token,
        },
        onUploadProgress: (progressEvent) => {
          const referenceFilePercent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (referenceFilePercent >= 100) {
            this.setState({ ["referenceFilePercentages" + pctIndex]: 100 });
          } else {
            this.setState({
              ["referenceFilePercentages" + pctIndex]: referenceFilePercent,
            });
          }
        },
      };

      this.setState({ ["referenceFilePercentages" + pctIndex]: 0 });

      const referenceDocumentFormData = new FormData();

      referenceDocumentFormData.append(
        "document[title]",
        file.name.replace(/\..*$/, "")
      );
      referenceDocumentFormData.append("document[document_file]", file);
      referenceDocumentFormData.append("document[reference_document]", true);
      referenceDocumentFormData.append(
        "document[library_id]",
        this.state.libraryId
      );
      referenceDocumentFormData.append("document[shared_reference]", true);

      this.setState({ referenceDocumentFilename: file.name });

      axios
        .post("/documents.json", referenceDocumentFormData, postConfig)
        .then((response) => {
          let referenceDocs = this.state.referenceDocuments;
          referenceDocs.push(response.data);
          referenceDocs.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
          });

          let newReferenceFiles = this.state.referenceFiles;
          this.state.referenceFiles.map((file, index) => {
            if (
              file.name ===
              response.data.title + response.data.file_extension
            ) {
              newReferenceFiles.splice(index, 1);
            }
          });

          this.setState({
            referenceDocuments: referenceDocs,
            referenceFiles: newReferenceFiles,
          });
        })
        .catch((error) => {
          let failedUploads = this.state.failedUploads;
          failedUploads.push(file);

          let failedUploadFileName = file.name;

          let newReferenceFiles = this.state.referenceFiles;
          this.state.referenceFiles.map((file, index) => {
            if (file.name === failedUploadFileName) {
              newReferenceFiles.splice(index, 1);
            }
          });

          this.setState({
            referenceFiles: newReferenceFiles,
            ["referenceFilePercentages" + pctIndex]: 100,
            failedUploads,
          });

          notificationService.addNotification(
            "Upload Failed",
            "File failed to upload",
            "warning"
          );
        });
    });
  };

  renderDocumentList = () => {
    const actionsDropDown = ({ onClick }) => {
      return (
        <div
          className={styles.documentsActionsDropDownContainer}
          onClick={onClick}
        >
          <img src={sortIcon} alt="sort_icon" />
        </div>
      );
    };
    return (
      <div className={styles.referencesContainer}>
        <div id="documents_container">
          <div className={styles.searchFormContainer}>
            <div className={styles.sortContainer}>
              {!this.state.searchField && (
                <div>
                  <Uikon
                    className={styles.searchIconDisabled}
                    onClick={() => this.handleOpenSearch()}
                  >
                    search_left
                  </Uikon>
                </div>
              )}
              <UikDropdown
                DisplayComponent={actionsDropDown}
                position="bottomRight"
              >
                <UikDropdownItem onClick={(e) => this.setSort("alpha_asc")}>
                  <span
                    className={
                      this.state.sort == "alpha_asc" ? styles.filterActive : ""
                    }
                  >
                    A ➝ Z
                  </span>
                </UikDropdownItem>

                <UikDropdownItem onClick={(e) => this.setSort("alpha_desc")}>
                  <span
                    className={
                      this.state.sort == "alpha_desc" ? styles.filterActive : ""
                    }
                  >
                    Z ➝ A
                  </span>
                </UikDropdownItem>
                <UikDropdownItem onClick={(e) => this.setSort("updated_asc")}>
                  <span
                    className={
                      this.state.sort == "updated_asc"
                        ? styles.filterActive
                        : ""
                    }
                  >
                    Oldest ➝ Newest
                  </span>
                </UikDropdownItem>
                <UikDropdownItem onClick={(e) => this.setSort("updated_desc")}>
                  <span
                    className={
                      this.state.sort == "updated_desc"
                        ? styles.filterActive
                        : ""
                    }
                  >
                    Newest ➝ Oldest
                  </span>
                </UikDropdownItem>
              </UikDropdown>
              <div className={"reference-upload-button"}>
                <Dropzone
                  onDrop={this.onUploadReferenceDocument}
                  multiple={true}
                  disabled={
                    this.state.userRole !== "viewer" &&
                    !this.props.documentApproved
                      ? false
                      : true
                  }
                >
                  {({ getRootProps, getInputProps, isDragActive }) => {
                    return (
                      <div
                        {...getRootProps()}
                        className={styles.emptyStateDropzone}
                      >
                        <input {...getInputProps()} />
                        <div
                          className={
                            this.state.userRole !== "viewer" &&
                            !this.props.documentApproved
                              ? "attachment-upload-button-empty"
                              : "attachment-upload-button-empty-viewer"
                          }
                        >
                          <Button original text={"Upload"} />
                        </div>
                      </div>
                    );
                  }}
                </Dropzone>
              </div>
            </div>
            <Link to={"/reference_libraries"} className={styles.leftIcon}>
              <img src={leftArrow} />
            </Link>
            <span>
              <h3
                className={`${
                  this.state.searchField
                    ? styles.truncatedTitle
                    : styles.searchTitleDisabled
                }`}
              >
                {this.state.library.name}
              </h3>
            </span>
            {this.state.searchField && (
              <div className={styles.searchFieldContainer}>
                <div
                  onClick={() => this.handleCloseSearch()}
                  className={styles.searchClose}
                >
                  &times;
                </div>
                <div className={styles.searchInputContainer}>
                  <UikInput
                    autoFocus
                    className={styles.searchInput}
                    placeholder="Search Reference Documents"
                    onChange={(e) => this.handleDocumentSearch(e)}
                    value={this.state.search}
                  />
                </div>
                <Uikon className={styles.searchIcon}>search_left</Uikon>
              </div>
            )}
          </div>
          <div className={styles.documentList}>
            {this.state.searchField && (
              <h3 className={styles.searchTitle}>Search Results</h3>
            )}
            {this.state.failedUploads.map((doc, index) => {
              return (
                <div key={doc.id}>
                  <DocumentThumbnail
                    key={index}
                    index={index}
                    doc={doc}
                    uploadProgress={
                      this.renderReferenceDocumentsUploaderProgress
                    }
                    filesUploaded={true}
                    refError={true}
                    closeFailedReference={this.closeFailedReference}
                  />
                </div>
              );
            })}
            {this.state.referenceFiles.map((doc, index) => {
              return (
                <div key={doc.id}>
                  <DocumentThumbnail
                    key={index}
                    index={index}
                    doc={doc}
                    uploadProgress={
                      this.renderReferenceDocumentsUploaderProgress
                    }
                    filesUploaded={true}
                    refError={null}
                    closeFailedReference={this.closeFailedReference}
                  />
                </div>
              );
            })}
            {this.state.referenceDocuments.map((doc, index) => {
              return (
                <div key={doc.id}>
                  <DocumentThumbnail key={index} doc={doc} />
                </div>
              );
            })}
            {this.state.referenceDocuments.length === 0 &&
              this.state.referenceFiles.length === 0 &&
              this.state.failedUploads.length === 0 && (
                <div className={styles.noDocuments}>No documents found</div>
              )}
            <div className="clear" />
            {this.state.menuOpen && (
              <div className={styles.documentListOver}></div>
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Container fluid className={styles.container}>
        <Header />

        {this.renderDocumentList()}
      </Container>
    );
  }
}

export default References;
