import ActionTypes from "./types";
import httpClient from "../../lib/HttpClient";
import moment from "moment";

export default (docId, search, commentor, date, sort) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const yesterdaysDate = moment().subtract(1, "days").format("YYYY-MM-DD");
      let commentData;
      if (date === yesterdaysDate) {
        commentData = {
          conversation: {
            document_id: parseInt(docId),
          },
          q: {
            body_cont: search,
            user_id_eq: commentor,
            created_at_eq: date,
            sort: sort,
          },
        };
      } else {
        commentData = {
          conversation: {
            document_id: parseInt(docId),
          },
          q: {
            body_cont: search,
            user_id_eq: commentor,
            created_at_gteq: date,
            sort: sort,
          },
        };
      }
      const response = await httpClient.post(
        "/comments/search.json",
        commentData
      );

      const resultLibrary = {
        searchTerm: search,
        searchResults: response.data,
      };
      dispatch({
        type: ActionTypes.SEARCH_COMMENTS,
        payload: resultLibrary,
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
