/* External libraries */
import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import history from "../../history";
import httpClient from "../../lib/HttpClient";

import security from "../../services/Security";
import notificationService from "../../services/Notifications";

/* UI Kit */
import { UikInput, UikButton, Uikon } from "@uik";
import "@uik/styles.css";
import "../../font.scss";

/* Papercurve Components */
import Header from "../shared/Header/Header";
import SettingsLeftMenu from "../shared/SettingsLeftMenu/SettingsLeftMenu";
import TagListItem from "./TagListItem";
import TagListItemsHeader from "./TagListItemsHeader";
import TagEditForm from "./TagEditForm";
import TagCreateForm from "./TagCreateForm";

/* Assets */
import styles from "./Tags.module.scss";

/* Variables */

class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      filteredTags: [],
      selectedTag: null,
      tagSearchQuery: null,
    };
  }

  componentWillMount = () => {
    this.loadTags();
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.match.params.id != nextProps.match.params.id) {
      this.state.tags.map((tag, index) => {
        if (tag.id == nextProps.match.params.id) {
          this.setState({ selectedTag: tag });
        }
      });
    }
  };

  loadTags = () => {
    const selectedTagId = this.props.match.params.id;
    const currentUserRole = security.getUserRole();

    // Only Authors and Admins can see tags screen
    if (currentUserRole !== "author" && currentUserRole !== "admin") {
      history.push("/drafts");
    }

    httpClient.get("/tags.json").then((response) => {
      let selectedTag = null;
      response.data.map((tag, index) => {
        if (tag.id == selectedTagId) {
          selectedTag = tag;
        }
      });
      this.setState({
        tags: response.data,
        filteredTags: response.data,
        selectedTag: selectedTag,
      });
    });
  };

  handleSubmit = (e) => {};

  renderTagList = () => {
    const selectedTagId = this.props.match.params.id;
    const letterHeaders = [];
    return (
      <div>
        {this.state.filteredTags.map((tag, index) => {
          const letter = tag.name.charAt(0).toUpperCase();
          const headerExists = letterHeaders.includes(letter.toLowerCase());

          if (!headerExists) {
            letterHeaders.push(letter.toLowerCase());
          }

          return (
            <div key={tag.id}>
              {!headerExists && <TagListItemsHeader letter={letter} />}
              <TagListItem tag={tag} selectedTagId={selectedTagId} />
            </div>
          );
        })}
      </div>
    );
  };

  handleTagSearch = (e) => {
    e.preventDefault();
    let tags = this.state.tags;
    let tagSearchQuery = e.target.value;

    tags = tags.filter(function (tag) {
      let matchingTag = false;
      if (tag.name.toLowerCase().indexOf(tagSearchQuery) != -1) {
        matchingTag = true;
      }
      return matchingTag;
    });
    this.setState({ filteredTags: tags });
  };

  handleTagCreate = (tagFormData) => {
    httpClient
      .post(`/tags.json`, tagFormData)
      .then((response) => {
        this.loadTags();
        history.push("/tags");
        notificationService.addNotification(
          "Tag Created",
          "Tag has been created",
          "success"
        );
      })
      .catch((e, r) => {
        if (e.response.status === 409) {
          notificationService.addNotification(
            "Tag Exists",
            "Tag already exists",
            "danger"
          );
        } else {
          let errorMessage = "";

          for (const i in e.response.data) {
            errorMessage += i + ": " + e.response.data[i];
          }

          notificationService.addNotification(
            "Tag Create Error",
            errorMessage,
            "danger"
          );
        }
      });
  };

  renderTagCreateForm = () => {
    return (
      <div>
        <TagCreateForm handleTagCreate={this.handleTagCreate} />
      </div>
    );
  };

  renderTagEditForm = () => {
    return (
      <div>
        {this.props.match.params.id && this.state.selectedTag && (
          <TagEditForm
            key={this.state.selectedTag.id}
            onDelete={() => {
              this.setState({ selectedTag: null }, () => {
                this.loadTags();
              });
            }}
            tag={this.state.selectedTag}
            loadTags={this.loadTags}
          />
        )}
      </div>
    );
  };

  render() {
    return (
      <Container fluid className={styles.tagsScreen}>
        <Header />
        <Row className={styles.tagsScreen__container}>
          <SettingsLeftMenu />
          <Col sm={12} md={5} className={styles.tagsScreen__tagListContainer}>
            <div className={styles.tagsScreen__tagSearch}>
              <Uikon>search_left</Uikon>
              <div className={styles.tagSearch__inputContainer}>
                <UikInput
                  className={styles.tagsScreen__tagSearchInput}
                  placeholder="Search Tag"
                  onChange={(e) => this.handleTagSearch(e)}
                />
              </div>
              <div className={styles.tagButtonContainer}>
                <Link to="/tags/create">
                  <UikButton>Create Tag</UikButton>
                </Link>
              </div>
            </div>
            <div className={styles.tagsScreen__tagList}>
              {this.renderTagList()}
            </div>
          </Col>
          <Col sm={12} md={5}>
            {this.props.match.params.id == "create"
              ? this.renderTagCreateForm()
              : this.renderTagEditForm()}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Tags;
