import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import Cookies from "universal-cookie";
import { Line } from "rc-progress";
import Autosuggest from "react-autosuggest";
import DatePicker from "react-datepicker";
import moment from "moment";
import security from "../../services/Security";
import history from "../../history";
import httpClient from "../../lib/HttpClient";
import Modal from "react-awesome-modal";
import NewModal from "../Common/Modal";

import "react-datepicker/dist/react-datepicker.css";

import notificationService from "../../services/Notifications";
import errorIcon from "../../images/icons/16px/error.svg";
import Lock from "../../images/icons/svg/lock-grey.svg";
import externalLinkIcon from "../../images/icons/svg/external-link-icon.svg";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  Uikon,
  UikWidget,
  UikHeadline,
  UikHeadlineDesc,
  UikDivider,
  UikSelect,
} from "@uik";
import "@uik/styles.css";
import "../../font.scss";

import styles from "./ApproverForm.module.scss";

/* Papercurve Components */
import ApproverListItem from "./ApproverListItem";
import LibrarySelect from "../Libraries/LibrarySelect";
import ApproverAddedItem from "./ApproverAddedItem";
import Button from "../Common/Buttons/ButtonTwoState";
import ButtonPrimary from "../Common/Buttons/ButtonPrimary";

/* Variables */
const cookies = new Cookies();

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (value, users, approvers, suggestionsAdded) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  // const users = [
  //  { id: 1, full_name: 'Antonio Salumbides', first_name: 'Antonio', last_name: 'Salumbides', title: 'CTO' },
  //  { id: 2, full_name: 'Ryan Whitham', first_name: 'Ryan', last_name: 'Whitham', title: 'CEO' }
  // ]

  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  if (inputValue.toLowerCase().match(emailRegex)) {
    if (
      !(
        users.filter((user) => {
          if (user.email === inputValue) {
            return true;
          }
        }).length > 0
      )
    ) {
      users.push({
        id: -1,
        email: inputValue,
        active: true,
        first_name: "",
        last_name: "",
      });
    }
  }

  let output =
    inputLength === 0 || !users
      ? []
      : users.filter((user) => {
          // Check if they are already an approver.
          if (
            approvers.filter((approver) => {
              if (user.id == approver.id && user.email == approver.email) {
                return true;
              }
            }).length > 0
          ) {
            return false;
          }

          // Check if they were added already.
          if (
            suggestionsAdded.filter((userAdded) => {
              if (
                user.id == userAdded.suggestion.id &&
                user.email == userAdded.suggestion.email
              ) {
                return true;
              }
            }).length > 0
          ) {
            return false;
          }

          if (!user.active && !user.guest) {
            return false;
          }

          if (
            user.first_name.toLowerCase().slice(0, inputLength) ===
              inputValue ||
            user.last_name.toLowerCase().slice(0, inputLength) === inputValue ||
            user.email.toLowerCase().slice(0, inputLength) === inputValue
          ) {
            return true;
          } else {
            return false;
          }
        });
  return output;
};

const getSuggestionValue = (suggestion) => suggestion.name;

const approvalStatuses = [
  { label: "Pending", value: "pending", id: 3 },

  {
    label: "Approved with changes",
    value: "approve_with_changes",
    id: 2,
  },
  { label: "Approved", value: "approved", id: 1 },
  { label: "Submit new version", value: "submit_new_version", id: 0 },
];

class ApproverForm extends React.Component {
  constructor() {
    super();
    this.state = {
      value: "",
      suggestions: [],
      approvers: [],
      dueDate: null,
      pendingDueDate: null,
      approvable: false,
      hasRejects: false,
      document: {},
      suggestion: null,
      suggestionsAdded: [],
      suggestionsRemainingModalOpen: false,
      notes: "",
      revokeButtonDisabled: false,
      releaseButtonDisabled: false,
      showConfirmDueDateChangeModal: false,
      currentUser: {
        id: security.getUserId(),
        role: security.getUserRole(),
      },
      isObserver: true,
    };
  }

  componentWillMount = () => {
    this.load();
  };

  load = () => {
    const { docId } = this.props;

    httpClient.get("/users.json").then((response) => {
      this.setState({ users: response.data });
    });

    httpClient.get(`/documents/${docId}.json`).then((response) => {
      this.setState({ document: response.data });

      if (response.data.due_date) {
        this.setState({ dueDate: new Date(response.data.due_date) });
      }

      if (response.data.approvable === true) {
        this.setState({ approvable: true });
      }

      if (response.data.approvers) {
        this.setState({ approvers: response.data.approvers }, () => {
          this.setApprovable();
          this.setRevokable();
        });
      }
    });
    httpClient.get(`/documents/${docId}/metadata.json`).then((response) => {
      this.setState({ metadata: response.data });
    });
  };

  handleDueDateChange = (date) => {
    this.setState({
      pendingDueDate: date,
      showConfirmDueDateChangeModal: true,
    });
  };

  setRevokable = () => {
    this.state.approvers.map((approver) => {
      if (approver.id === this.state.currentUser.id && !approver.observer) {
        this.setState({ isObserver: false });
      }
    });
  };

  setApprovable = () => {
    let approvable = true;
    this.state.approvers.map((approver, index) => {
      if (!approver.observer && (approver.active || approver.guest)) {
        approvable =
          approvable && (approver.approved || approver.approve_with_changes);
      }
      if (approver.submit_new_version) {
        this.setState({ hasRejects: true });
      }
    });
    if (this.state.approvers.length > 0 && approvable) {
      this.setState({ approvable: true });
    } else {
      this.setState({ approvable: false });
    }
  };

  onChange = (event, { newValue }) => {
    if (newValue) {
      this.setState({
        value: newValue,
      });
    } else {
      this.setState({
        value: "",
      });
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const { users, approvers, suggestionsAdded } = this.state;
    this.setState({
      suggestions: getSuggestions(value, users, approvers, suggestionsAdded),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (e, { suggestion }) => {
    let suggestionsAdded = this.state.suggestionsAdded;

    if (!suggestionsAdded.find((sug) => sug.email === suggestion.email)) {
      suggestionsAdded.push({
        value: suggestion.full_name,
        suggestion: suggestion,
      });

      this.setState({
        value: "",
        suggestion: null,
        suggestionsAdded: suggestionsAdded,
      });
    }
  };

  onSuggestionSend = () => {
    const { docId } = this.props;
    let suggestionsAdded = this.state.suggestionsAdded;

    suggestionsAdded.map((added, index) => {
      const approverData = {
        approval: {
          document_id: this.state.document.id,
          user_id: added.suggestion.id,
          observer: added.suggestion.observer,
          notes: this.state.notes,
          guest_email_address: added.suggestion.email,
        },
      };

      httpClient
        .post(
          `/documents/${this.state.document.id}/approvers.json`,
          approverData
        )
        .then((response) => {
          let suggestion = null;
          let addedIndex = null;
          let suggestionsAddedSub = this.state.suggestionsAdded;
          for (let i = 0; i < this.state.suggestionsAdded.length; i++) {
            if (
              this.state.suggestionsAdded[i].suggestion.id ==
              response.data.user_id
            ) {
              suggestion = this.state.suggestionsAdded[i].suggestion;
              addedIndex = i;
            }
          }

          if (suggestion === null) {
            suggestion = { guest: true };
          }

          suggestion.approval_id = response.data.id;

          const indexOfApprover = this.state.approvers.indexOf(suggestion);

          if (indexOfApprover === -1) {
            this.state.approvers.push(suggestion);
          }

          this.setApprovable();
          suggestionsAddedSub.splice(addedIndex, 1);

          httpClient.get(`/documents/${docId}.json`).then((response) => {
            this.setState({
              suggestionsAdded: suggestionsAddedSub,
              approvers: response.data.approvers,
              document: response.data,
            });
          });
        });

      //this.state.approvers.push(suggestion);
    });
  };

  onApprovalStatusChange = (approval, status) => {
    let statusId = 3;
    let approvalStatus = null;
    for (let i in approvalStatuses) {
      if (approvalStatuses[i].value == status) {
        statusId = approvalStatuses[i].value;
        approvalStatus = approvalStatuses[i];
      }
    }
    const approvalFormData = {
      approval: {
        status: statusId,
      },
    };

    httpClient
      .put(`/approvals/${approval.approval_id}.json`, approvalFormData)
      .then((response) => {
        this.load();
        notificationService.addNotification(
          "Approval Reset",
          "Review status has been reset.",
          "success"
        );
      });
  };

  onObserver = (approval, v) => {
    if (v.target.checked) {
      const approvalFormData = {
        approval: {
          observer: true,
        },
      };

      httpClient
        .put(`/approvals/${approval.approval_id}.json`, approvalFormData)
        .then((response) => {
          this.load();
        });
    } else {
      const approvalFormData = {
        approval: {
          observer: false,
        },
      };

      httpClient
        .put(`/approvals/${approval.approval_id}.json`, approvalFormData)
        .then((response) => {
          this.load();
        });
    }
  };

  onDelete = (approver) => {
    httpClient
      .delete(`/approvals/${approver.approval_id}.json`)
      .then((response) => {
        const approvers = [...this.state.approvers];
        const indexOfApprover = this.state.approvers.indexOf(approver);
        if (indexOfApprover !== -1) {
          approvers.splice(indexOfApprover, 1);
          this.setState({ approvers });
        }
        this.setApprovable();
      });
  };

  onCopyReviewLink = (approver) => {
    this.state.document.approvals.forEach((approval) => {
      if (approval.id == approver.approval_id) {
        navigator.clipboard.writeText(
          `${window.location.origin}/guest/view/${approval.review_link.hash_url}`
        );

        notificationService.addNotification(
          "Link copied",
          `Your Review Link has been copied to clipboard!`,
          "success"
        );
      }
    });
  };

  onResendReviewLinkEmail = (approver) => {
    this.state.document.approvals.forEach((approval) => {
      if (approval.id == approver.approval_id) {
        httpClient
          .post(`/approvals/${approver.approval_id}/resend.json`, {
            id: approver.approval_id,
          })
          .then((response) => {
            notificationService.addNotification(
              "Email Resent",
              `Review Link Email Resent to ${approver.email}`,
              "success"
            );
          });
      }
    });
  };

  onLibraryChange = (selectedLibrary) => {
    const documentFormData = {
      document: {
        library_id: selectedLibrary.value,
      },
    };

    httpClient
      .put(`/documents/${this.state.document.id}.json`, documentFormData)
      .then((response) => {
        this.setState({
          document: response.data,
        });
      });
  };

  renderInputComponent = (inputProps) => (
    <div>
      <UikInput
        disabled={this.state.document.approved}
        label="ADD REVIEWER"
        {...inputProps}
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
      />
    </div>
  );

  renderSuggestion = (suggestion) => (
    <div className={styles.approverAutosuggestItem}>
      <ApproverListItem suggestMode user={suggestion} />
    </div>
  );

  approveDocument = (e) => {
    e.preventDefault();
    this.setState({ revokeReleaseButtonDisabled: true });

    if (!this.state.document.library_id) {
      alert("Please select a libary.");
      return false;
    }

    const documentFormData = {
      document: {
        approved: true,
      },
    };

    httpClient
      .put(`/documents/${this.state.document.id}.json`, documentFormData)
      .then((response) => {
        this.setState({
          document: response.data,
          revokeReleaseButtonDisabled: false,
        });
        history.push("/document_libraries");
      })
      .catch((error) => {
        this.setState({ releaseButtonDisabled: false });
        if (error.response.status === 422) {
          alert(error.response.data.approved[0]);
        }
      });
  };

  unApproveDocument = (e) => {
    e.preventDefault();
    this.setState({ revokeReleaseButtonDisabled: true });

    const documentFormData = {
      document: {
        approved: false,
      },
    };

    httpClient
      .put(`/documents/${this.state.document.id}.json`, documentFormData)
      .then((response) => {
        this.setState({
          document: response.data,
        });
        this.setState({ revokeReleaseButtonDisabled: false });
        this.props.revoke();
      });
  };

  removeAdded = (fullName) => {
    let suggestionsAdded = this.state.suggestionsAdded;
    this.state.suggestionsAdded.map((added, index) => {
      if (added.value == fullName) {
        suggestionsAdded.splice(index, 1);
      }
    });
    this.setState({ suggestionsAdded: suggestionsAdded });
  };

  suggestionObserverChange = (sug, val) => {
    let suggestionsAdded = this.state.suggestionsAdded;
    this.state.suggestionsAdded.map((added, index) => {
      if (added.suggestion.id == sug.id) {
        added.suggestion.observer = val.target.checked;
      }
    });
    this.setState({ suggestionsAdded: suggestionsAdded });
  };

  renderSuggestionsAdded = () => {
    if (this.state.suggestionsAdded.length > 0) {
      return (
        <div className={styles.approverListItems}>
          {this.state.suggestionsAdded.map((added, index) => {
            return (
              <ApproverAddedItem
                key={added.suggestion.email}
                added={added}
                onDeleteAdded={this.removeAdded}
                onChangeObserverStatus={this.suggestionObserverChange}
              />
            );
          })}
          <div className={styles.approverFormDetails}>
            <Row className={styles.approverFormInputs}>
              <Col>
                <div>
                  <span className="uik-content-title__wrapper">
                    NOTES TO REVIEWERS
                  </span>
                </div>
                <textarea
                  className={`uik-input__input `}
                  style={{ width: "100%" }}
                  rows="5"
                  value={this.state.notes}
                  onChange={(e) => this.handleNotesChange(e)}
                ></textarea>

                <div className={styles.addApproverButton}>
                  <UikButton
                    className="uik-btn__base uik-btn__primary"
                    onClick={(e) => this.onSuggestionSend(e)}
                    disabled={
                      this.state.suggestionsAdded.length > 0 ? false : true
                    }
                  >
                    Send
                  </UikButton>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
  };

  allApproversObservers = () => {
    let onlyObservers = true;

    this.state.approvers.map((approver) => {
      if (!approver.observer) {
        onlyObservers = false;
      }
    });

    return onlyObservers;
  };

  renderApprovers = () => {
    return (
      <div className={styles.approverListItems + " " + styles.added}>
        {this.state.approvers.map((approver, index) => {
          return (
            <ApproverListItem
              approvedMode={this.state.document.approved}
              key={index}
              user={approver}
              onApproved={this.onApproved}
              onObserver={this.onObserver}
              onDelete={this.onDelete}
              extraClass={"added"}
              readOnly={this.props.readOnly}
              onApproveWithChanges={this.onApproveWithChanges}
              approvalStatuses={approvalStatuses}
              onApprovalStatusChange={this.onApprovalStatusChange}
              onCopyReviewLink={this.onCopyReviewLink}
              onResendReviewLinkEmail={this.onResendReviewLinkEmail}
            />
          );
        })}
      </div>
    );
  };

  checkSuggestionsRemaining = (e) => {
    e.preventDefault();
    let suggestionsRemainingCount = this.state.suggestionsAdded.length;
    if (suggestionsRemainingCount === 0) {
      this.backToDocument();
    } else {
      this.handleOpenSuggestionsRemainingModal();
    }
  };

  exportApprovals = (e) => {
    let exportApprovalsPage = window.open(
      `${
        window.location.protocol +
        "//" +
        window.location.host +
        "/exportApprovals"
      }`
    );
    let documentData = {
      title: this.state.document.title,
      metadata: this.state.metadata,
      approvers: this.state.approvers,
      tags: this.state.document.tags_list,
      library: this.state.document.library.name,
      everyone_approved: this.state.document.everyone_approved,
      approvals_date: this.state.document.approvals_date,
      total_approvals: this.state.document.total_approvals,
      pending_approvals: this.state.document.pending_approvals,
      allApproversObservers: this.allApproversObservers(),
    };
    exportApprovalsPage.data = documentData;
  };

  backToDocument = () => {
    if (this.state.document.approved) {
      history.push({
        pathname: `/documents/${this.props.originalDocumentId}`,
        state: {
          versionId: this.props.versionId,
          versionNumber: this.props.versionNumber,
        },
      });
    } else {
      history.push({
        pathname: `/drafts/${this.props.originalDocumentId}`,
        state: {
          versionId: null,
          versionNumber: this.props.versionNumber,
        },
      });
    }
  };

  deleteDocument = (e) => {
    e.preventDefault();

    const confirmed = window.confirm(
      `Are you sure you want to delete ${this.state.document.title} ?`
    );

    if (confirmed) {
      httpClient
        .delete(`/documents/${this.state.document.id}.json`)
        .then((response) => {
          const backLink = window.location.pathname.match(/^\/drafts/)
            ? "/drafts"
            : "/document_libraries";

          history.push(backLink);
        });
    }
  };

  handleOpenSuggestionsRemainingModal = () => {
    this.setState({ suggestionsRemainingModalOpen: true });
  };

  handleCloseSuggestionsRemainingModal = (e) => {
    e.preventDefault();
    this.setState({ suggestionsRemainingModalOpen: false });
  };

  handleNotesChange = (e) => {
    this.setState({ notes: e.target.value });
  };

  handleReleaseRevoke = (e) => {
    if (this.state.document.approved) {
      this.unApproveDocument(e);
    } else {
      this.approveDocument(e);
    }
  };

  confirmDueDateChange = () => {
    const documentFormData = {
      document: {
        due_date: this.state.pendingDueDate,
      },
    };

    httpClient
      .put(`/documents/${this.state.document.id}.json`, documentFormData)
      .then((response) => {
        this.setState({
          dueDate: this.state.pendingDueDate,
          pendingDueDate: null,
          showConfirmDueDateChangeModal: false,
        });
        notificationService.addNotification(
          "Due Date Updated",
          "The due date has been updated.",
          "success"
        );
      });
  };

  cancelDueDateChange = () => {
    this.setState({
      pendingDueDate: null,
      showConfirmDueDateChangeModal: false,
    });
  };

  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: "Type a name or email",
      value,
      onChange: this.onChange,
    };

    return (
      <div>
        <Container fluid>
          {((!this.state.document.approved &&
            this.state.approvable &&
            !this.state.hasRejects) ||
            this.state.document.approved) && (
            <div className={styles.releaseContentForm}>
              <h1 className={styles.releaseContentFormHeadline}>
                {!this.state.document.approved &&
                  this.state.approvable &&
                  `Everyone has approved`}
                {this.state.document.approved && `Released`}
              </h1>
              {this.state.currentUser.role !== "viewer" &&
                this.state.document.everyone_approved &&
                !this.allApproversObservers() && (
                  <div className={styles.releaseContentFormCaption}>
                    {`Completed on ${moment(
                      this.state.document.approvals_date
                    ).format("MMM D, YYYY")}`}
                  </div>
                )}
              {(this.state.currentUser.role === "admin" ||
                !this.state.isObserver) && (
                <div className={styles.releaseContentFormCaption}>
                  {!this.state.document.approved &&
                    this.state.approvable &&
                    `You can start another round of approvals or move this content to the "Approved" section.`}
                  {this.state.document.approved &&
                    `You can revoke this released document.`}
                </div>
              )}
              {(this.state.currentUser.role === "admin" ||
                !this.state.isObserver ||
                (this.state.isObserver &&
                  this.state.currentUser.role === "author") ||
                this.state.document.uploaded_by_user_id ===
                  this.state.currentUser.id) && (
                <Button
                  activeText={
                    !this.state.document.approved && this.state.approvable
                      ? "Release content"
                      : "Revoke content"
                  }
                  disabledText={
                    !this.state.document.approved && this.state.approvable
                      ? "Releasing..."
                      : "Revoking..."
                  }
                  disabled={this.state.revokeReleaseButtonDisabled}
                  onClick={this.handleReleaseRevoke}
                />
              )}
            </div>
          )}
          {!this.state.document.approved &&
            this.state.document.submit_new_version &&
            !this.state.approvable &&
            this.state.hasRejects && (
              <div className={styles.revokeContentForm}>
                <h1 className={styles.revokeContentFormHeadline}>
                  New Version Requested
                </h1>
                <div className={styles.revokeContentFormCaption}>
                  You can upload a new version or remove this content.
                </div>

                <UikButton
                  transparent
                  className={styles.revokeContentFormAction}
                  disabled={this.props.readOnly}
                >
                  <Link
                    className={styles.uploadNewVersionLink}
                    to={`/drafts/${this.state.document.id}/update`}
                  >
                    Upload new
                  </Link>
                </UikButton>

                <UikButton
                  transparent
                  className={styles.revokeContentFormAction}
                  onClick={(e) => this.deleteDocument(e)}
                >
                  Delete
                </UikButton>
              </div>
            )}
          {!this.props.readOnly && (
            <div className={styles.approverForm}>
              <div className={styles.addApproverContainer}>
                <div className={styles.addApproverInput}>
                  <UikFormInputGroup className={styles.approverFormInputs}>
                    <Autosuggest
                      suggestions={suggestions}
                      suggestion={this.state.suggestion}
                      onSuggestionsFetchRequested={
                        this.onSuggestionsFetchRequested
                      }
                      onSuggestionsClearRequested={
                        this.onSuggestionsClearRequested
                      }
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={this.renderSuggestion}
                      renderInputComponent={this.renderInputComponent}
                      inputProps={inputProps}
                      focusInputOnSuggestionClick={false}
                      highlightFirstSuggestion
                      onSuggestionSelected={this.onSuggestionSelected}
                    />
                    <div className={styles.approverListItemInner}>
                      <div className={styles.approverListItemNameplate}>
                        <span className={styles.guestReviewerDescription}>
                          Type someone's name or email address to add as a
                          reviewer. &nbsp;
                          <a
                            href="https://help.papercurve.com/knowledge-base/review-link"
                            target="_blank"
                            className={styles.kbLink}
                          >
                            Learn More
                            <img
                              className={styles.externalLink}
                              src={externalLinkIcon}
                            />
                          </a>
                        </span>
                      </div>
                    </div>
                  </UikFormInputGroup>
                </div>
              </div>
              <div className="clear"></div>
              {this.renderSuggestionsAdded()}
            </div>
          )}

          <div className={styles.approverForm + " " + styles.added}>
            {this.renderApprovers()}
            <div className={styles.approverFormDetails}>
              <Row className={styles.approverFormInputs}>
                {Object.keys(this.state.document).length !== 0 && (
                  <React.Fragment>
                    <Col sm={6}>
                      <LibrarySelect
                        onLibraryChange={(selectedLibrary) =>
                          this.onLibraryChange(selectedLibrary)
                        }
                        library_id={this.state.document.library_id}
                        library_name={this.state.document.library_name}
                        readOnly={
                          this.props.readOnly || this.props.libraryReadOnly
                        }
                      />
                      {this.props.libraryReadOnly && (
                        <div className={styles.libraryNotUpdatable}>
                          <img src={Lock} />
                          <div className={styles.libraryNotUpdatableText}>
                            Library cannot be changed since there are shared
                            references in this document.
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col sm={6}>
                      <div className={styles.dueDateLabel}>
                        <span className="uik-content-title__wrapper">
                          DUE DATE
                        </span>
                      </div>
                      <DatePicker
                        disabled={this.state.document.approved}
                        className={"uik-input__input " + styles.dueDateInput}
                        selected={this.state.dueDate}
                        onChange={this.handleDueDateChange}
                        disabled={this.props.readOnly}
                      />
                    </Col>
                  </React.Fragment>
                )}
              </Row>
            </div>
          </div>
        </Container>
        <Container fluid className={styles.actionButtons}>
          <div className="floatLeft">
            <UikButton
              className={styles.approverFormDoneButton}
              onClick={(e) => this.checkSuggestionsRemaining(e)}
            >
              Back
            </UikButton>
            <UikButton
              className={styles.exportButton}
              onClick={(e) => this.exportApprovals(e)}
            >
              Export
              <img className={styles.externalLink} src={externalLinkIcon} />
            </UikButton>
          </div>
          <Modal
            visible={this.state.suggestionsRemainingModalOpen}
            width="540"
            height="275"
            effect="fadeInUp"
            onClickAway={(e) => this.handleCloseSuggestionsRemainingModal(e)}
          >
            <div className={styles.suggestionsRemainingContentContainer}>
              <div>
                <h1 className={styles.suggestionsRemainingContentHeadline}>
                  Leaving without sending?
                </h1>
              </div>
              <UikDivider />
              <div className={styles.suggestionsRemainingContentFormContainer}>
                <h1 className={styles.suggestionsRemainingContentText}>
                  You have started to add approvers but have not clicked the
                  "Send for appproval" button. Are you sure you want to leave
                  without sending?
                </h1>
              </div>
              <UikDivider />
              <div className={styles.suggestionsRemainingContentFormButtons}>
                <UikButton
                  className={[
                    styles.suggestionsRemainingStayButton,
                    "floatRight",
                  ]}
                  onClick={(e) => this.handleCloseSuggestionsRemainingModal(e)}
                  primary
                >
                  Stay
                </UikButton>
                <UikButton
                  className={[
                    styles.suggestionsRemainingLeaveButton,
                    "floatRight",
                  ]}
                  onClick={(e) => this.backToDocument(e)}
                >
                  Yes, Leave
                </UikButton>
                <div className="clear" />
              </div>
            </div>
          </Modal>
        </Container>
        <NewModal
          visible={this.state.showConfirmDueDateChangeModal}
          type="alert"
        >
          <div className="heading">Confirm Due Date Change</div>
          <UikDivider />
          <div className="body">
            Are you sure you want to change the due date? Reviewers on this
            document will be notified.
          </div>
          <div className="clear" />
          <UikDivider />
          <div className="buttons">
            <ButtonPrimary
              onClick={this.confirmDueDateChange}
              original
              text="Confirm"
            />
            <ButtonPrimary
              onClick={this.cancelDueDateChange}
              transparent
              text="Cancel"
            />
          </div>
        </NewModal>
      </div>
    );
  }
}

export default ApproverForm;
