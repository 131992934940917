import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-grid-system";

import httpClient from "../../lib/HttpClient";
import security from "../../services/Security";

/* Assets */
import styles from "./ReferenceLibraries.module.scss";
import referenceLibraryIcon from "../../images/icons/16px/reference_library_icon.svg";
import lockIcon from "../../images/icons/svg/lock.svg";

/* Papercurve Components */
import Header from "../shared/Header/Header";

/* Variables */

class ReferenceLibraries extends React.Component {
  constructor() {
    super();
    this.state = {
      libraries: [],
      librariesFetched: false,
    };
  }

  componentWillMount = () => {
    const currentUserId = security.getUserId();

    httpClient
      .get(`/users/${currentUserId}/libraries.json`)
      .then((response) => {
        this.setState({
          libraries: response.data,
          librariesFetched: true,
        });
      });
  };

  render() {
    return (
      <Container fluid className="users-screen">
        <Header />
        <div className={styles.referenceLibrariesContainer}>
          {this.state.libraries.length > 0 && (
            <h3 className={styles.searchTitleDisabled}>Libraries</h3>
          )}

          {this.state.libraries.length > 0 && (
            <div className={styles.documentList}>
              {this.state.libraries.map((library, index) => (
                <div key={index} className={styles.documentContainer}>
                  <Link to={`/libraries/${library.id}/references`}>
                    <div className={styles.heading}>{library.name}</div>
                    <div className={styles.iconContainer}>
                      <img
                        className={styles.libraryIcon}
                        src={referenceLibraryIcon}
                      />
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          )}

          {this.state.libraries.length == 0 && this.state.librariesFetched && (
            <div>
              <img className={styles.lockIcon} src={lockIcon} />
              <h3 className={styles.noLibraryMessage}>
                Uh oh, you don’t have access to any libraries
              </h3>
              <h4 className={styles.noLibrarySubMessage}>
                Contact an admin to give you access.
              </h4>
            </div>
          )}
        </div>
      </Container>
    );
  }
}

export default ReferenceLibraries;
