import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styles from "./Comment.module.scss";
import { useSelector } from "react-redux";

import deleteIcon from "../../images/icons/16px/delete2.svg";
import emailIcon from "../../images/icons/svg/email-icon.svg";

import videoService from "../../services/Video";

const Comment = ({ comment, currentUserId, onDeleteComment, canDelete }) => {
  const mention = new RegExp("{{(.*?)}}", "gi");
  const search = useSelector((state) => {
    if (state.commentSearchResults) {
      return state.commentSearchResults.searchTerm;
    }
  });

  const formatHighlights = (comment) => {
    let parts = comment.body ? comment.body.split(mention) : [];

    if (parts.length === 1) {
      parts = comment.body
        ? comment.body.split(new RegExp(`(${search})`, "gi"))
        : [];
    }
    const names = comment.body.match(mention);

    return (
      <span>
        {" "}
        {parts.map((part, i) => {
          const found = names && names.find((name) => name === `{{${part}}}`);
          if (part.match(new RegExp(`(${search})`, "gi"))) {
            let subsections = part.split(new RegExp(`(${search})`, "gi"));
            return subsections.map((section) => {
              if (
                search &&
                section.trim().toLowerCase() === search.toLowerCase()
              ) {
                return <span className={styles.highlight}>{section}</span>;
              } else {
                const higlightType =
                  currentUserId === comment.user.id
                    ? styles.myCommentHighlight
                    : styles.othersCommentHighlight;
                return (
                  <span key={i} className={found ? higlightType : {}}>
                    {section}
                  </span>
                );
              }
            });
          }
          if (search && part.trim().toLowerCase() === search.toLowerCase()) {
            return (
              <span key={i} className={styles.highlight}>
                {part}
              </span>
            );
          } else {
            const higlightType =
              currentUserId === comment.user.id
                ? styles.myCommentHighlight
                : styles.othersCommentHighlight;
            return (
              <span key={i} className={found ? higlightType : {}}>
                {part}
              </span>
            );
          }
        })}{" "}
      </span>
    );
  };

  return (
    <div className={canDelete ? " " + styles.commentCanDelete : ""}>
      <div className={styles.commentHeader}>
        <div
          className={
            styles.commentNamePlateContainer +
            (!comment.user.active ? " " + styles.commentNamePlateDeactive : "")
          }
        >
          {comment.user.guest && (
            <span className={styles.commentNamePlate}>
              <img src={emailIcon} />
            </span>
          )}
          {!comment.user.guest && (
            <span className={styles.commentNamePlate}>
              {comment.user.first_name.charAt(0)}
              {comment.user.last_name.charAt(0)}
            </span>
          )}
        </div>
        <div className={styles.nameContainer}>
          <div className={styles.commentName}>
            {comment.user.guest && <span>{comment.user.email}</span>}
            {!comment.user.guest && (
              <span>
                {comment.user.first_name} {comment.user.last_name}
              </span>
            )}
            {!comment.user.active && (
              <span>
                <br />
                (Deactivated)
              </span>
            )}
          </div>
        </div>
        <div className={styles.commentHeaderRight}>
          <div className={styles.commentDate}>
            {moment(comment.commented_at).format("MMMM Do")}
          </div>
          <div className={styles.commentDelete}>
            <img
              onClick={() => {
                onDeleteComment(comment.id);
              }}
              className={styles.deleteCommentIcon}
              src={deleteIcon}
            />
          </div>
        </div>
      </div>
      <div
        className={
          currentUserId === comment.user.id
            ? styles.commentFromMe
            : styles.commentFromOthers
        }
      >
        {formatHighlights(comment)}
        {comment.video_timestamp != null && (
          <div
            className={styles.timestamp}
            onClick={() => {
              videoService.setCurrentTime(comment.video_timestamp);
            }}
          >
            at{" "}
            {moment()
              .startOf("day")
              .seconds(comment.video_timestamp)
              .format("H:mm:ss")}
          </div>
        )}
      </div>
    </div>
  );
};

Comment.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  comment: PropTypes.shape({
    id: PropTypes.number,
    comment: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string,
  }).isRequired,
};

export default Comment;
