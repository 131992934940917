import React, { useState, useEffect } from "react";
import CloseSVG from "../../../../images/icons/svg/close.svg";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Button from "../../../Common/Buttons/ButtonPrimary";
import fetchDocumentDynamicFields from "../../../../actions/document/fetchDocumentDynamicFields";
import Dropzone from "react-dropzone";
import { loadDocumentUrl } from "../../../../lib/axios-client";
import { Line } from "rc-progress";
import notificationService from "../../../../services/Notifications";

import greenCheck from "../../../../images/icons/svg/greenCheck.svg";
import deleteImage from "../../../../images/icons/svg/delete-image.svg";

import axios from "../../../../lib/axios-client";
import httpClient from "../../../../lib/HttpClient";
import security from "../../../../services/Security";
import useDebouncer from "../../../../hooks/useDebouncer";

import styles from "./Template.module.scss";

/* UI Kit */
import { UikInput } from "@uik";

export default (props) => {
  const [dynamicFieldData, setDynamicFieldData] = useState({});

  const [activeImageField, setActiveImageField] = useState(null);

  const [dynamicImageFields, setDynamicImageFields] = useState([]);

  const [imageLoading, setImageLoading] = useState({});

  const [progress, setProgress] = useState(0);

  const [imageDimension, setImageDimension] = useState(null);

  const debouncedInputValue = useDebouncer(imageDimension);

  useEffect(() => {
    debouncedInputValue && changeImageDimension();
  }, [debouncedInputValue]);

  const documentDynamicFields = useSelector((state) => {
    return state.documentDynamicFields;
  });

  const { viewingCurrentVersion, closePanel, templateFields, docId, readOnly } =
    props;

  const onClose = () => {
    closePanel();
  };

  const dispatch = useDispatch();

  const fieldNameNoSpaces = (fieldName) => {
    let withoutSpaces = fieldName.split(" ").join("");
    return withoutSpaces;
  };

  const saveDocumentDynamicFields = () => {
    const fieldData = new FormData();

    const fill_datetime = moment().unix();
    if (documentDynamicFields.length === 0) {
      templateFields.map((field) => {
        let regex = /Image:/i;
        let isImageField = field.match(regex);
        if (!isImageField) {
          if (!dynamicFieldData[field]) {
            fieldData.append(`fields[${field}][field_value]`, null);
            fieldData.append(`fields[${field}][document_id]`, docId);
            fieldData.append(`fields[${field}][fill_datetime]`, fill_datetime);
          } else {
            fieldData.append(
              `fields[${field}][field_value]`,
              dynamicFieldData[field]["field_value"]
            );
            fieldData.append(
              `fields[${field}][document_id]`,
              dynamicFieldData[field]["document_id"]
            );
            fieldData.append(`fields[${field}][fill_datetime]`, fill_datetime);
          }
        } else {
          let filledImageField = dynamicImageFields.find(
            (imageField) =>
              fieldNameNoSpaces(`Image: ${imageField.fieldName}`) ===
              fieldNameNoSpaces(field)
          );
          if (filledImageField) {
            fieldData.append(`fields[${field}][field_name]`, field);
            fieldData.append(`fields[${field}][field_value]`, null);
            fieldData.append(`fields[${field}][document_id]`, docId);
            fieldData.append(`fields[${field}][fill_datetime]`, fill_datetime);
            fieldData.append(
              `fields[${field}][image_file]`,
              filledImageField.imageFile
            );
            fieldData.append(
              `fields[${field}][image_width]`,
              filledImageField.width
            );
            fieldData.append(
              `fields[${field}][image_height]`,
              filledImageField.height
            );
          } else {
            fieldData.append(`fields[${field}][field_value]`, null);
            fieldData.append(`fields[${field}][document_id]`, docId);
            fieldData.append(`fields[${field}][fill_datetime]`, fill_datetime);
          }
        }
      });
    } else {
      Object.keys(dynamicFieldData).map((field) => {
        fieldData.append(
          `fields[${field}][field_value]`,
          dynamicFieldData[field]["field_value"]
        );
        fieldData.append(
          `fields[${field}][document_id]`,
          dynamicFieldData[field]["document_id"]
        );
        fieldData.append(`fields[${field}][fill_datetime]`, fill_datetime);
      });

      if (dynamicImageFields.length > 0) {
        dynamicImageFields.map((imageField) => {
          let correspondingTemplateField = templateFields.find(
            (f) =>
              fieldNameNoSpaces(f) ===
              fieldNameNoSpaces(`Image: ${imageField.fieldName}`)
          );

          fieldData.append(
            `fields[${correspondingTemplateField}][field_name]`,
            correspondingTemplateField
          );
          fieldData.append(
            `fields[${correspondingTemplateField}][field_value]`,
            null
          );
          fieldData.append(
            `fields[${correspondingTemplateField}][document_id]`,
            docId
          );
          fieldData.append(
            `fields[${correspondingTemplateField}][fill_datetime]`,
            fill_datetime
          );
          fieldData.append(
            `fields[${correspondingTemplateField}][image_file]`,
            imageField.imageFile
          );
          fieldData.append(
            `fields[${correspondingTemplateField}][image_width]`,
            imageField.width
          );
          fieldData.append(
            `fields[${correspondingTemplateField}][image_height]`,
            imageField.height
          );
        });
      }
    }

    const token = security.getToken();

    const postConfig = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios
      .post("/document_dynamic_fields.json", fieldData, postConfig)
      .then((res) => {
        notificationService.addNotification(
          "Template updated and new version created",
          "Template updated and new version created",
          "success"
        );
        let updatedFields = [];
        dispatch(fetchDocumentDynamicFields(docId)).then((response) => {
          updatedFields = [...response];

          let options = {};

          if (documentDynamicFields.length === 0) {
            let updatedDocumentDynamicFields = [...templateFields];

            res.data.saved_template_fields.map((field) => {
              if (field.has_attached_image) {
                let updatedImageField = updatedDocumentDynamicFields.find(
                  (f) => f === field.field_name
                );

                let index = templateFields.indexOf(updatedImageField);
                if (index || index === 0) {
                  updatedDocumentDynamicFields[index] = field;
                }
              } else {
                let index = templateFields.indexOf(
                  updatedDocumentDynamicFields.find(
                    (f) => f === field.field_name
                  )
                );
                if (index || index === 0) {
                  updatedDocumentDynamicFields[index] = field;
                }
              }
            });

            updatedDocumentDynamicFields.map((field) => {
              let regex = /Image:/;

              if (field.field_value && field.field_value !== "null") {
                options[`${field.field_name}`] = `${field.field_value}`;
              } else if (!field.field_name && field.match(regex)) {
                options[field] = `{{${field}}}`;
              } else {
                options[`${field.field_name}`] = `{{${field.field_name}}}`;
              }
            });

            updatedDocumentDynamicFields.map((field, index) => {
              if (field.has_attached_image) {
                options[templateFields[index]] = {
                  image_url: `${loadDocumentUrl()}/document_dynamic_field/image/${
                    field.id
                  }`,
                  width: parseInt(field.image_width),
                  height: parseInt(field.image_height),
                };
              }
            });
          } else {
            templateFields.map((templateField, index) => {
              let imageFieldIndex;
              const foundInUpdatedFields = updatedFields.find((f) => {
                if (f.has_attached_image) {
                  if (f.field_name === templateField) {
                    imageFieldIndex = index;
                    return true;
                  }
                } else {
                  return f.field_name === templateField;
                }
              });

              if (foundInUpdatedFields) {
                if (
                  foundInUpdatedFields.field_value &&
                  foundInUpdatedFields.field_value !== "null"
                ) {
                  options[
                    `${foundInUpdatedFields.field_name}`
                  ] = `${foundInUpdatedFields.field_value}`;
                } else {
                  options[
                    `${foundInUpdatedFields.field_name}`
                  ] = `{{${foundInUpdatedFields.field_name}}}`;
                }

                if (foundInUpdatedFields.has_attached_image) {
                  options[foundInUpdatedFields.field_name] = {
                    image_url: `${loadDocumentUrl()}/document_dynamic_field/image/${
                      foundInUpdatedFields.id
                    }`,
                    width: parseInt(foundInUpdatedFields.image_width),
                    height: parseInt(foundInUpdatedFields.image_height),
                  };
                }
              } else {
                options[`${templateField}`] = `{{${templateField}}}`;
              }
            });
          }

          setDynamicFieldData({});

          const webViewerEl = document.getElementById("webViewer");
          window.WebViewer.getInstance(webViewerEl)
            .docViewer.getDocument()
            .applyTemplateValues(options);
          setDynamicImageFields([]);
        });
      })
      .catch((error) => {});
  };

  const onChangeFieldValue = (e, isImageField, parameter) => {
    if (isImageField) {
      let modifiedImageField = dynamicImageFields.find(
        (f) => f.fieldName === e.target.name
      );

      modifiedImageField[parameter] = e.target.value;

      let modifiedImageFieldIndex = [...dynamicImageFields].indexOf(
        modifiedImageField
      );

      let updatedDocumentImageFields = [...dynamicImageFields];

      updatedDocumentImageFields[modifiedImageFieldIndex] = modifiedImageField;

      setDynamicImageFields(updatedDocumentImageFields);
    } else {
      setDynamicFieldData({
        ...dynamicFieldData,
        [e.target.name]: {
          field_value: e.target.value,
          document_id: docId,
        },
      });
    }
  };

  const returnFieldValue = (field) => {
    if (dynamicFieldData[field]) {
      return dynamicFieldData[field].field_value;
    } else {
      const documentDynamicField = documentDynamicFields.find(
        (f) => f.field_name === field
      );
      if (documentDynamicField) {
        if (documentDynamicField.field_value === "null") {
          return "";
        } else {
          return documentDynamicField.field_value;
        }
      } else {
        return "";
      }
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getImageFieldName = (field) => {
    let regex = /Image:(.*)/;
    let fieldNameParts;
    if (field.field) {
      fieldNameParts = field.field.match(regex);
    } else {
      fieldNameParts = field.match(regex);
    }

    let fieldName = "";

    fieldNameParts.map((part, index) => {
      if (index > 0) {
        fieldName = fieldName.concat(part).trim();
      }
    });

    return fieldName;
  };

  const returnUpdatedImageFieldValue = (field, dimension) => {
    if (
      imageDimension &&
      imageDimension.field.field === field.field &&
      imageDimension.dimension === dimension
    ) {
      return imageDimension.value;
    } else {
      const documentDynamicField = documentDynamicFields.find(
        (f) => f.field_name === field.field
      );

      return documentDynamicField ? documentDynamicField[dimension] : null;
    }
  };

  const onUpdateImageFieldValue = (value, field, dimension) => {
    setImageDimension({
      value,
      field,
      dimension,
    });
  };

  const changeImageDimension = () => {
    const { value, field, dimension } = imageDimension;

    const documentDynamicField = documentDynamicFields.find(
      (f) => f.field_name === field.field
    );

    let fields = {
      id: documentDynamicField.id,
      dimension: dimension,
      [dimension]: parseInt(value),
    };
    httpClient
      .put(`/document_dynamic_fields/${documentDynamicField.id}.json`, {
        fields,
      })
      .then((res) => {
        let updatedFields = [];
        dispatch(fetchDocumentDynamicFields(docId)).then((response) => {
          updatedFields = [...response];

          let options = {};

          templateFields.map((templateField) => {
            const foundInUpdatedFields = updatedFields.find((f) => {
              return f.field_name === templateField;
            });

            if (foundInUpdatedFields) {
              if (
                foundInUpdatedFields.field_value &&
                foundInUpdatedFields.field_value !== "null"
              ) {
                options[
                  `${foundInUpdatedFields.field_name}`
                ] = `${foundInUpdatedFields.field_value}`;
              } else {
                options[
                  `${foundInUpdatedFields.field_name}`
                ] = `{{${foundInUpdatedFields.field_name}}}`;
              }

              if (foundInUpdatedFields.has_attached_image) {
                options[foundInUpdatedFields.field_name] = {
                  image_url: `${loadDocumentUrl()}/document_dynamic_field/image/${
                    foundInUpdatedFields.id
                  }`,
                  width: parseInt(foundInUpdatedFields.image_width),
                  height: parseInt(foundInUpdatedFields.image_height),
                };
              }
            } else {
              options[`${templateField}`] = `{{${templateField}}}`;
            }
          });

          const webViewerEl = document.getElementById("webViewer");
          window.WebViewer.getInstance(webViewerEl)
            .docViewer.getDocument()
            .applyTemplateValues(options);
          setDynamicImageFields([]);
        });
      });
  };

  const renderLoadingState = () => {
    const placeholderArray = [1, 2, 3, 4, 5, 6, 7, 8];
    return placeholderArray.map(() => {
      return (
        <div>
          <div className={styles.labelPlaceHolder}></div>
          <div className={styles.inputPlaceHolder}></div>
        </div>
      );
    });
  };

  const renderProgress = () => {
    let prevPercentage = 0;
    setTimeout(() => {
      while (prevPercentage < 100) {
        setProgress(prevPercentage + 20);
        prevPercentage += 20;
      }
    }, 500);

    setTimeout(() => {
      setImageLoading(null);
      setActiveImageField(null);
    }, 1000);
  };

  const renderTemplateFields = () => {
    return templateFields
      .sort(function (a, b) {
        if (a.field_name < b.field_name) {
          return -1;
        }
        if (a.field_name > b.field_name) {
          return 1;
        }
        return 0;
      })
      .map((field) => {
        let regex = /Image:/;
        let isImageField = field.match(regex);
        if (!isImageField) {
          return (
            <div className={styles.inputContainer}>
              <div className={styles.inputHeader}>
                {capitalizeFirstLetter(field)}
              </div>
              <UikInput
                disabled={!viewingCurrentVersion || readOnly}
                placeholder={"Value"}
                className={styles.searchInputField}
                name={field}
                onChange={(e) => onChangeFieldValue(e)}
                value={returnFieldValue(field)}
              ></UikInput>
            </div>
          );
        }
      });
  };

  const deleteDynamicImageField = (field) => {
    const documentDynamicField = documentDynamicFields.find(
      (f) => f.field_name === field.field
    );

    let fields = {
      id: documentDynamicField.id,
    };
    httpClient
      .delete(`/document_dynamic_fields/${documentDynamicField.id}.json`, {
        fields,
      })
      .then((res) => {
        let updatedFields = [];

        dispatch(fetchDocumentDynamicFields(docId)).then((response) => {
          updatedFields = [...response];

          let options = {};

          templateFields.map((templateField) => {
            const foundInUpdatedFields = updatedFields.find((f) => {
              return f.field_name === templateField;
            });

            if (foundInUpdatedFields) {
              if (
                foundInUpdatedFields.field_value &&
                foundInUpdatedFields.field_value !== "null"
              ) {
                options[
                  `${foundInUpdatedFields.field_name}`
                ] = `${foundInUpdatedFields.field_value}`;
              } else {
                options[
                  `${foundInUpdatedFields.field_name}`
                ] = `{{${foundInUpdatedFields.field_name}}}`;
              }

              if (foundInUpdatedFields.has_attached_image) {
                options[foundInUpdatedFields.field_name] = {
                  image_url: `${loadDocumentUrl()}/document_dynamic_field/image/${
                    foundInUpdatedFields.id
                  }`,
                  width: parseInt(foundInUpdatedFields.image_width),
                  height: parseInt(foundInUpdatedFields.image_height),
                };
              }
            } else {
              options[`${templateField}`] = `{{${templateField}}}`;
            }
          });

          const webViewerEl = document.getElementById("webViewer");
          window.WebViewer.getInstance(webViewerEl)
            .docViewer.getDocument()
            .applyTemplateValues(options);
          setDynamicImageFields([]);
        });
      });
  };

  const renderCreateClaimForm = () => {
    return (
      <div className={styles.claimsFormContainer}>
        <div className={styles.claimsForm}>
          <div className={styles.formContainer}>
            {templateFields.length > 0
              ? renderTemplateFields()
              : renderLoadingState()}
          </div>
        </div>
      </div>
    );
  };

  const renderTemplateImageUploader = () => {
    let imageFields = [];

    templateFields.map((field) => {
      let imageFieldRegex = /Image:/;
      let isImageField = field.match(imageFieldRegex);
      if (isImageField) {
        imageFields.push({ field });
      }
    });

    return imageFields.map((field, index) => {
      let fieldName = getImageFieldName(field);

      let filledField = documentDynamicFields.find(
        (f) => f.field_name === field.field && f.has_attached_image
      );

      let imageUploaded = dynamicImageFields.find(
        (f) => f.fieldName === fieldName
      );

      let currentFieldActive =
        activeImageField && activeImageField.field === field.field;

      return (
        <div
          className={styles.imageFieldInput}
          onDragEnter={() => {
            setActiveImageField(field);
          }}
        >
          <div className={styles.inputHeader}>
            {capitalizeFirstLetter(fieldName)}
          </div>
          {!imageUploaded && !filledField && (
            <Dropzone
              accept="image/*"
              onDrop={onImageFileDrop}
              multiple={true}
              disabled={!viewingCurrentVersion || readOnly}
            >
              {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <div
                    {...getRootProps()}
                    className={styles.emptyStateDropzone}
                  >
                    <input {...getInputProps()} />
                    <div className={styles.uploaderContainer}>
                      <span
                        className={styles.emptyStateUploaderIconContainer}
                      ></span>
                      <div className={styles.uploadButtonContainer}>
                        <Button
                          disabled={!viewingCurrentVersion || readOnly}
                          original
                          text={"Upload"}
                          onClick={() => {
                            setActiveImageField(field);
                          }}
                        />
                        <span className={styles.uploadCaption}>
                          or drop file here to upload
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }}
            </Dropzone>
          )}
          {(imageUploaded || filledField) && (
            <div className={styles.progressContainer}>
              <div className={styles.fileDetailsContainer}>
                <div className={styles.fileName}>
                  {imageUploaded
                    ? capitalizeFirstLetter(
                        imageUploaded.fileName.replace(/\.[^/.]+$/, "")
                      )
                    : capitalizeFirstLetter(
                        filledField.image_file_name.replace(/\.[^/.]+$/, "")
                      )}
                </div>
                <div className={styles.fileType}>
                  {imageUploaded
                    ? imageUploaded.fileType
                        .replace(/image\//, "")
                        .toUpperCase()
                    : filledField.mime_type
                        .replace(/image\//, "")
                        .toUpperCase()}
                </div>
                {imageLoading && currentFieldActive && (
                  <Line
                    className={styles.uploadProgress}
                    percent={progress}
                    strokeWidth="1"
                    strokeColor="#1b8c96"
                    strokeLinecap="square"
                  />
                )}
              </div>
              {(currentFieldActive && !imageLoading) ||
                (!currentFieldActive && (
                  <img
                    className={
                      !viewingCurrentVersion || readOnly
                        ? styles.successIconApproved
                        : styles.successIcon
                    }
                    src={greenCheck}
                  />
                ))}
              {filledField && viewingCurrentVersion && !readOnly && (
                <img
                  className={styles.deleteImageField}
                  src={deleteImage}
                  onClick={() => {
                    deleteDynamicImageField(field);
                  }}
                />
              )}
            </div>
          )}
          {(currentFieldActive && !imageLoading) ||
            (!currentFieldActive && imageUploaded && (
              <div className={styles.imageDimensionsInputContainer}>
                <div className={styles.dimensionInputContainer}>
                  <div className={styles.inputHeader}>{"Width"}</div>
                  <UikInput
                    disabled={!viewingCurrentVersion || readOnly}
                    placeholder={"Value"}
                    className={styles.searchInputField}
                    name={`${fieldName}`}
                    onChange={(e) => onChangeFieldValue(e, true, "width")}
                    // value={returnFieldValue("width")}
                  ></UikInput>
                </div>
                <div className={styles.dimensionInputContainer}>
                  <div className={styles.inputHeader}>{"Height"}</div>
                  <UikInput
                    disabled={!viewingCurrentVersion || readOnly}
                    placeholder={"Value"}
                    className={styles.searchInputField}
                    name={`${fieldName}`}
                    onChange={(e) => onChangeFieldValue(e, true, "height")}
                    // value={returnFieldValue("width")}
                  ></UikInput>
                </div>
              </div>
            ))}
          {filledField && (
            <div className={styles.imageDimensionsInputContainer}>
              <div className={styles.dimensionInputContainer}>
                <div className={styles.inputHeader}>{"Width"}</div>
                <UikInput
                  disabled={!viewingCurrentVersion || readOnly}
                  placeholder={"Value"}
                  className={styles.searchInputField}
                  name={`${fieldName}`}
                  onChange={(e) =>
                    onUpdateImageFieldValue(
                      e.target.value,
                      field,
                      "image_width"
                    )
                  }
                  value={returnUpdatedImageFieldValue(field, "image_width")}
                ></UikInput>
              </div>
              <div className={styles.dimensionInputContainer}>
                <div className={styles.inputHeader}>{"Height"}</div>
                <UikInput
                  disabled={!viewingCurrentVersion || readOnly}
                  placeholder={"Value"}
                  className={styles.searchInputField}
                  name={`${fieldName}`}
                  onChange={(e) =>
                    onUpdateImageFieldValue(
                      e.target.value,
                      field,
                      "image_height"
                    )
                  }
                  value={returnUpdatedImageFieldValue(field, "image_height")}
                ></UikInput>
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  const onImageFileDrop = (acceptedFile) => {
    setImageLoading({
      fieldName: activeImageField.field,
      fileName: acceptedFile[0].name,
      fileType: acceptedFile[0].type,
    });
    renderProgress();

    let fieldName = getImageFieldName(activeImageField.field);

    let updatedImageField = {
      fieldName: fieldName,
      imageFile: acceptedFile[0],
      fileName: acceptedFile[0].name,
      fileType: acceptedFile[0].type,
      width: 64,
      height: 64,
    };

    let updatedImageFields = [...dynamicImageFields, updatedImageField];

    setDynamicImageFields(updatedImageFields);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.text}>Editable Fields</div>
        <div className={styles.closeIcon}>
          <img src={CloseSVG} onClick={onClose} />
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.dynamicFieldsContainer}>
          {renderTemplateImageUploader()}
          {renderCreateClaimForm()}
        </div>
      </div>
      {viewingCurrentVersion && (
        <div className={styles.formButtonContainer}>
          <div className={styles.formButton}>
            <Button
              disabled={!viewingCurrentVersion || readOnly}
              original
              text={templateFields.length > 0 ? "Apply Fields" : ""}
              onClick={(e) => {
                saveDocumentDynamicFields(e);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
