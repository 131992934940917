import React from "react";
import PropTypes from "prop-types";
import { Link, Router, Route } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import { createForm, formShape } from "rc-form";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { Line } from "rc-progress";
import sanitize from "sanitize-filename";
import CreatableSelect from "react-select/creatable";
import notificationService from "../../../services/Notifications";
import { connect } from "react-redux";
import Modal from "react-awesome-modal";
import Tooltip from "../../Common/Tooltip";

import fetchDocumentTemplates from "../../../actions/document/fetchDocumentTemplates";

import history from "../../../history";
import axios from "../../../lib/axios-client";
import httpClient from "../../../lib/HttpClient";
import Button from "../../Common/Buttons/ButtonPrimary";
import NewModal from "../../Common/Modal";
import DocumentTemplatesTable from "./DocumentTemplatesTable";

import Checkmark from "../../../images/icons/svg/checkmark-upload-success.svg";
import referenceIcon from "../../../images/icons/16px/link.svg";
import ReferenceIcon from "../../../images/icons/16px/link.svg";
import Warning from "../../../images/icons/png/warning.svg";
import warningSmall from "../../../images/icons/svg/warning-small.svg";
import UploadIcon from "../../../images/icons/svg/upload.svg";
import DocumentIcon from "../../../images/icons/svg/document-file-grey.svg";
import externalLinkIcon from "../../../images/icons/svg/external-link-icon.svg";

import security from "../../../services/Security";

import aws from "../../../services/AWS";

/* UI Kit */
import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  Uikon,
  UikWidget,
  UikHeadline,
  UikHeadlineDesc,
  UikDivider,
  UikSelect,
  UikButtonGroup,
  UikToggle,
} from "@uik";
import "@uik/styles.css";
import "../../../font.scss";

/* Papercurve Components */
import InputField from "../../Documents/DocumentFields/InputField";
import DropDownField from "../../Documents/DocumentFields/DropDownField";
import TextareaField from "../../Documents/DocumentFields/TextareaField";
import DateField from "../../Documents/DocumentFields/DateField";
import LibrarySelect from "../../Libraries/LibrarySelect";
import AudienceSelect from "../../Documents/DocumentFields/AudienceSelect";

import Spinner from "../../Common/Spinner";
import TemplatePreviewModal from "./TemplatePreviewModal.js";
import styles from "./DraftUploadForm.module.scss";

class DraftUploadFormBuild extends React.Component {
  constructor() {
    super();
    this.state = {
      mainDocumentPercent: null,
      mainDocumentTitle: null,
      mainDocumentFilename: null,
      mainDocument: null,
      referenceFiles: [],
      documentFields: [],
      documentFieldValues: {},
      selectOptions: {},
      errors: {},
      error_message: "",
      error: false,
      pdfOnlyMessage:
        "Only PDF, DOC(X), XLS(X) and PPT(X) files are supported at this time.",
      referenceDocumentError: false,
      mainDocumentFileUploaded: false,
      tags_list: [],
      availableTags: [],
      selectedLibrary: null,
      libraryfieldError: "",
      mainDocumentUploadError: false,
      fileTabActive: true,
      templateTabActive: false,
      renameTemplateModalOpen: false,
      deleteTemplateModalOpen: false,
      selectedTemplate: null,
      showTemplatePreviewModal: false,
      templateUploadError: null,
      currentUserRole: security.getUserRole(),
      autoApprove: false,
      passwordInvalid: false,
      suppressSequential: false,
      passwordAbsent: false,
    };
  }

  componentWillMount = () => {
    window.onhashchange = function () {
      if (window.location.hash.includes("template")) {
        window.location.reload();
      }
    };

    if (window.location.hash.includes("template")) {
      const numberPattern = /\d+/g;

      const templateId = parseInt(window.location.hash.match(numberPattern)[0]);

      const libraryId = parseInt(window.location.hash.match(numberPattern)[1]);

      this.props.fetchDocumentTemplates(libraryId).then((response) => {
        const templateToSelect = response.find(
          (template) => template.id === templateId
        );

        this.onLibraryChange({ value: libraryId });

        this.setState({
          selectedLibrary: { value: libraryId },
          templateTabActive: true,
          fileTabActive: false,
          selectedTemplate: templateToSelect,
        });
      });
    }

    httpClient.get("/tags.json").then((response) => {
      const tags = [];
      response.data.map((option, index) => {
        tags.push({ value: option.name, label: option.name });
      });
      this.setState({ availableTags: tags });
    });
  };

  setTemplateTabActive = () => {
    this.setState({
      fileTabActive: false,
      templateTabActive: true,
    });
  };

  setFileTabActive = () => {
    this.setState({
      fileTabActive: true,
      templateTabActive: false,
    });
  };

  onMainDocumentDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({
      mainDocumentUploadError: false,
    });
    if (acceptedFiles[0].type.match("video.*")) {
      this.onMainVideoDrop(acceptedFiles, rejectedFiles);
      return;
    }

    const title = acceptedFiles[0].name.replace(/\.[^.]*$/, "");
    const token = security.getToken();

    const postConfig = {
      headers: {
        Authorization: "Bearer " + token,
      },
      onUploadProgress: (progressEvent) => {
        const mainDocumentPercent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (mainDocumentPercent >= 100) {
          this.setState({ mainDocumentPercent: 100 });
        } else {
          this.setState({ mainDocumentPercent });
        }
      },
    };

    const documentFormData = new FormData();
    documentFormData.append("document[title]", title);
    documentFormData.append("document[document_file]", acceptedFiles[0]);
    documentFormData.append(
      "document[library_id]",
      this.state.selectedLibrary.value
    );

    this.setState({
      mainDocumentPercent: 0,
      mainDocumentTitle: title,
      mainDocumentFilename: title,
    });

    axios
      .post("/documents.json", documentFormData, postConfig)
      .then((response) => {
        this.setState({
          mainDocument: response.data,
          mainDocumentFileUploaded: true,
          error: false,
          errors: {},
          error_message: "",
        });
        httpClient
          .get(`/documents/${response.data.id}/metadata.json`)
          .then((response) => {
            let documentFieldValues = {};

            response.data.map((metadata, idx) => {
              documentFieldValues[metadata.document_field.key] = metadata.value;
            });

            this.setState({
              documentFieldValues: documentFieldValues,
            });
          });
      })
      .catch((error) => {
        security.checkAuth(error);
        console.log("error", error);

        notificationService.addNotification(
          "File upload failed",
          "File Failed to upload.",
          "warning"
        );

        this.setState({
          mainDocumentUploadError: true,
          mainDocumentPercent: null,
          mainDocument: null,
          errors: error.response.data,
          error: true,
        });
      });
  };

  setPasswordInvalid = (option) => {
    this.setState({ passwordInvalid: option });
  };

  setPassword = (password) => {
    this.setPasswordAbsent(false);
    this.setState({ password });
  };

  setPasswordAbsent = (option) => {
    this.setState({ passwordAbsent: option });
  };

  handleDeleteTemplate = () => {
    httpClient
      .delete(`/document_templates/${this.state.templateId}.json`)
      .then((response) => {
        this.handleCloseDeleteTemplateModal();

        this.props.fetchDocumentTemplates(this.state.selectedLibrary.value);

        notificationService.addNotification(
          "Template removed.",
          "Template was successfully deleted.",
          "success"
        );
      })
      .catch(() => {
        notificationService.addNotification(
          "Template deletion not permitted",
          "You do not have permission to delete templates.",
          "warning"
        );
      });
  };

  handleDeleteTemplateModalOpen = (e, title, id) => {
    e.preventDefault();

    this.setState({
      deleteTemplateModalOpen: true,
      templateTitle: title,
      templateId: id,
    });
  };

  autoApproveContent = () => {
    if (this.state.autoApprove) {
      this.setState({
        autoApprove: false,
      });
    } else {
      this.setState({
        autoApprove: true,
      });
    }
  };

  handleCloseDeleteTemplateModal = () => {
    this.setState({ deleteTemplateModalOpen: false });
  };

  handleRenameTemplate = (e) => {
    e.preventDefault();
    this.props.form.validateFields((error, values) => {
      if (!error) {
        const templateFormData = {
          document_template: {
            title: values["title"],
          },
        };

        httpClient
          .put(
            `/document_templates/${this.state.templateId}.json`,
            templateFormData
          )
          .then((response) => {
            this.props.form.resetFields();

            this.handleCloseRenameTemplateModal();
            this.props.fetchDocumentTemplates(this.state.selectedLibrary.value);

            notificationService.addNotification(
              "Template renamed.",
              "Template has been renamed.",
              "success"
            );
          })
          .catch(() => {
            this.handleCloseRenameTemplateModal();
            notificationService.addNotification(
              "Template renaming not permitted.",
              "You are not permitted to rename templates.",
              "warning"
            );
          });
      } else {
        console.log("error", error, values);
      }
    });
  };

  handleRenameTemplateModalOpen = (e, title, id) => {
    e.preventDefault();

    this.setState({
      renameTemplateModalOpen: true,
      templateTitle: title,
      templateId: id,
    });
  };

  handleCloseRenameTemplateModal = () => {
    this.props.form.resetFields();
    this.setState({ renameTemplateModalOpen: false });
  };

  onTemplateDrop = (acceptedFiles, rejectedFiles) => {
    const title = acceptedFiles[0].name.replace(/\.[^.]*$/, "");
    const token = security.getToken();

    const postConfig = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    const templateFormData = new FormData();
    templateFormData.append("document_template[title]", title);
    templateFormData.append(
      "document_template[document_template_file]",
      acceptedFiles[0]
    );
    templateFormData.append(
      "document_template[library_id]",
      this.state.selectedLibrary.value
    );

    axios
      .post("/document_templates.json", templateFormData, postConfig)
      .then((response) => {
        this.props.fetchDocumentTemplates(this.state.selectedLibrary.value);

        this.setState({ templateUploadError: null });

        notificationService.addNotification(
          "New template has been uploaded",
          "New template has been uploaded.",
          "success"
        );
      })
      .catch((error) => {
        notificationService.addNotification(
          "File failed to upload",
          "File failed to upload",
          "warning"
        );
        if (error.response.status === 422) {
          this.setState({
            templateUploadError:
              "File type not supported, please try another file.",
          });
        } else {
          this.setState({
            templateUploadError: "Error uploading file, please try again.",
          });
        }
      });
  };

  onLibraryChange = (selectedLibrary) => {
    this.props.fetchDocumentTemplates(selectedLibrary.value);
    this.setState({
      selectedLibrary,
      libraryfieldError: "",
    });

    httpClient
      .get(`/libraries/${selectedLibrary.value}/document_fields.json`)
      .then((response) => {
        const documentFields = response.data;
        let selectOptions = {};

        documentFields.map((df) => {
          if (
            df.field_type === "select_options" ||
            df.field_type === "multi_select_options"
          ) {
            selectOptions[df.key] = df.document_field_select_options;
          }
        });

        this.setState({
          documentFields: documentFields,
          selectOptions: selectOptions,
        });
      });
  };

  onMainVideoDrop = (acceptedFiles, rejectedFiles) => {
    if (!acceptedFiles[0].type.match("video.*")) {
      alert("Please upload a video file only");

      return;
    }

    const title = acceptedFiles[0].name.replace(/\.[^.]*$/, "");

    httpClient.get("/aws/policy").then((response) => {
      const postConfig = {
        onUploadProgress: (progressEvent) => {
          const mainDocumentPercent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (mainDocumentPercent >= 100) {
            this.setState({ mainDocumentPercent: 100 });
          } else {
            this.setState({ mainDocumentPercent });
          }
        },
      };

      this.setState({
        mainDocumentPercent: 0,
        mainDocumentTitle: title,
        mainDocumentFilename: title,
      });

      let data = new FormData();
      const timestamp = new Date().getTime();

      const fileName = sanitize(acceptedFiles[0].name).replace(/[{()}]/g, "");
      const fileDirectory = `${response.data.directory}/${timestamp}/`;
      const fileLocation = `${response.data.directory}/${timestamp}/${fileName}`;

      data.append("key", fileLocation);
      data.append("acl", "private");
      data.append(
        "Content-Type",
        acceptedFiles[0].type != ""
          ? acceptedFiles[0].type
          : "application/octet-stream"
      );
      data.append("X-Amz-Credential", response.data.x_amz_credential);
      data.append("X-Amz-Algorithm", response.data.x_amz_algorithm);
      data.append("X-Amz-Date", response.data.x_amz_date);
      data.append("Policy", response.data.encoded_policy);
      data.append("X-Amz-Signature", response.data.x_amz_signature);
      data.append("file", acceptedFiles[0]);

      const url = `https://${response.data.bucket}.${response.data.s3_region_endpoint}/`;

      axios
        .post(url, data, postConfig)
        .then((response) => {
          let videoData = {};
          videoData.s3_url = `${url}${fileLocation}`;
          videoData.file_location = fileDirectory;
          videoData.file_name = fileName;
          videoData.file_type = acceptedFiles[0].type;
          videoData.title = title;
          videoData.library_id = this.state.selectedLibrary.value;
          httpClient
            .post("/aws/create_video", videoData)
            .then((response) => {
              this.setState({
                mainDocument: response.data,
                mainDocumentFileUploaded: true,
                error: false,
                errors: {},
                error_message: "",
              });
              httpClient
                .get(`/documents/${response.data.id}/metadata.json`)
                .then((response) => {
                  let documentFieldValues = {};

                  response.data.map((metadata, idx) => {
                    documentFieldValues[metadata.document_field.key] =
                      metadata.value;
                  });

                  this.setState({
                    documentFieldValues: documentFieldValues,
                  });
                });
            })
            .catch((error) => {
              console.log("error", error);

              notificationService.addNotification(
                "File upload failed",
                "File Failed to upload.",
                "warning"
              );

              this.setState({
                mainDocumentPercent: null,
                mainDocument: null,
                error_message:
                  "There was an error uploading your file. Ensure it is a valid video",
                error: true,
                mainDocumentUploadError: true,
              });
            });
        })
        .catch((error) => {
          console.log("error", error);

          notificationService.addNotification(
            "File upload failed",
            "File Failed to upload.",
            "warning"
          );

          this.setState({
            mainDocumentPercent: null,
            mainDocument: null,
            errors: ["There was an error uploading your file. "],
            error: true,
            mainDocumentUploadError: true,
          });
        });
    });
  };

  toggleSequentialSuppression = () => {
    if (this.state.suppressSequential) {
      this.setState({
        suppressSequential: false,
      });
    } else {
      this.setState({
        suppressSequential: true,
      });
    }
  };

  renderMainDocumentPanel = () => {
    return (
      <div>
        <span className={styles.uploaderIcon}>
          <Uikon>cloud_up</Uikon>
        </span>
        <span className={styles.uploaderPlaceholder}>
          Drop files here to upload
        </span>
        <UikButton className={styles.uploaderButton}>Browse</UikButton>
      </div>
    );
  };

  renderMainDocumentUploader = () => {
    let className = `${styles.mainContentUploader}${
      this.state.mainDocumentUploadError
        ? ` ${styles.mainContentUploaderError}`
        : ""
    }`;
    return (
      <div className={className}>
        <Dropzone onDrop={this.onMainDocumentDrop} multiple={false}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone", {
                  "dropzone--isActive": isDragActive,
                })}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div>
                    <span className={styles.uploaderIcon}>
                      <img src={UploadIcon} />
                    </span>
                    <span className={styles.uploaderPlaceholder}>
                      Drop approval document here
                    </span>
                  </div>
                ) : (
                  <div className={styles.uploaderBody}>
                    <div className={styles.contentLeft}>
                      <span className={styles.uploaderIcon}>
                        <img src={UploadIcon} />
                      </span>
                      <span className={styles.uploaderPlaceholder}>
                        Drop files here to upload
                      </span>
                    </div>
                    <div className={styles.contentRight}>
                      <Button transparent text="Browse">
                        Browse
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  };

  setShowTemplatePreviewModal = (option) => {
    this.setState({
      showTemplatePreviewModal: option,
    });
  };

  renderTemplateUploader = () => {
    return (
      <div className={styles.templateUploader}>
        <Dropzone onDrop={this.onTemplateDrop} multiple={false}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone", {
                  "dropzone--isActive": isDragActive,
                })}
              >
                <input {...getInputProps()} />
                <div className={styles.uploaderBody}>
                  <div className={styles.contentRight}>
                    <Button transparent text="Upload">
                      Upload
                    </Button>
                  </div>
                </div>
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  };

  renderMainDocumentUploaderProgress = () => {
    return (
      <div className={styles.mainContentUploading}>
        <div className={styles.firstRow}>
          <div className={styles.contentLeft}>
            <div className={styles.iconContainer}>
              <img src={DocumentIcon} />
            </div>
            <div className={styles.uploadingFilename}>
              {this.state.mainDocumentFilename}
            </div>
          </div>
          {this.state.mainDocumentFileUploaded && (
            <div className={styles.contentRight}>
              <div className={styles.checkmarkContainer}>
                <img src={Checkmark} />
              </div>
            </div>
          )}
        </div>
        {this.state.mainDocumentPercent >= 0 &&
          !this.state.mainDocumentFileUploaded && (
            <div>
              <Line
                className={styles.uploadProgress}
                percent={this.state.mainDocumentPercent}
                strokeWidth="1"
                strokeColor="#1b8c96"
                strokeLinecap="square"
              />
            </div>
          )}
      </div>
    );
  };

  onReferenceDocumentsDrop = (acceptedFiles, rejectedFiles) => {
    const token = security.getToken();

    this.setState({
      referenceFiles: this.state.referenceFiles.concat(acceptedFiles),
    });

    acceptedFiles.map((referenceFile, index) => {
      const pctIndex = this.state.referenceFiles.length + index;

      const postConfig = {
        headers: {
          Authorization: "Bearer " + token,
        },
        onUploadProgress: (progressEvent) => {
          const referenceFilePercent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          if (referenceFilePercent >= 100) {
            this.setState({ ["referenceFilePercentages" + pctIndex]: 100 });
          } else {
            this.setState({
              ["referenceFilePercentages" + pctIndex]: referenceFilePercent,
            });
          }
        },
      };

      this.setState({ ["referenceFilePercentages" + pctIndex]: 0 });

      const referenceFormData = new FormData();
      referenceFormData.append("document[title]", referenceFile.name);
      referenceFormData.append("document[document_file]", referenceFile);
      referenceFormData.append(
        "document[reference_document_id]",
        this.state.mainDocument.id
      );
      referenceFormData.append("document[reference_document]", true);

      axios
        .post("/documents.json", referenceFormData, postConfig)
        .then((response) => {
          this.setState({ referenceDocumentError: false, errors: {} });
        })
        .catch((error) => {
          security.checkAuth(error);
          console.log("error", error);
          this.setState({
            ["referenceFilePercentages" + pctIndex]: 0,
            ["referenceFileError" + pctIndex]: true,
            errors: error.response.data,
            referenceDocumentError: true,
          });
        });
    });
  };

  renderReferenceDocumentsUploader = () => {
    return (
      <div className={styles.referenceContentUploader}>
        <Dropzone onDrop={this.onReferenceDocumentsDrop} multiple={true}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone", {
                  "dropzone--isActive": isDragActive,
                })}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div>
                    <span className={styles.uploaderIcon}>
                      <Uikon>cloud_up</Uikon>
                    </span>
                    <span className={styles.uploaderPlaceholder}>
                      Drop reference documents here
                    </span>
                  </div>
                ) : (
                  <div>
                    <span className={styles.uploaderIcon}>
                      <Uikon>cloud_up</Uikon>
                    </span>
                    <span className={styles.uploaderPlaceholder}>
                      Drop files here to upload
                    </span>
                    <UikButton className={styles.uploaderButton}>
                      Browse
                    </UikButton>
                  </div>
                )}
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  };

  renderEnhancedSequentialValue = (field) => {
    let sequentialString = field.field_value;
    let replaceSequentialIdentifiers = field.field_value.match(/@[a-zA-Z_]+/g);

    if (replaceSequentialIdentifiers) {
      replaceSequentialIdentifiers.forEach((seqId) => {
        this.state.documentFields.map((docField, idx) => {
          let seq = seqId.replace("@", "");

          if (
            docField.field_type === "select_options" &&
            docField.key === seq
          ) {
            docField.document_field_select_options.forEach((dfso) => {
              if (this.state.documentFieldValues[seq]) {
                if (this.state.documentFieldValues[seq] == dfso.value) {
                  sequentialString = sequentialString.replace(
                    seqId,
                    dfso.sequential_identifier
                  );
                }
              } else if (dfso["default"]) {
                sequentialString = sequentialString.replace(
                  seqId,
                  dfso.sequential_identifier
                );
              }
            });
          }
        });
      });
    }

    sequentialString = sequentialString.replace("@papercurve_version", "1.0");

    return sequentialString;
  };

  renderDocumentFields = () => {
    const { getFieldError, getFieldDecorator } = this.props.form;
    const customCreatableSelectStyles = {
      control: (base) => ({
        ...base,
        maxHeight: 100,
        overflow: "auto",
        autoComplete: "off",
        role: "presentation",
      }),
    };

    return (
      <div className={styles.metadataContainer}>
        <div className={styles.contentPropertiesHeader}>content properties</div>
        <div className={styles.metadataSingleLineInputFields}>
          <div></div>
          {this.state.documentFields.map((field, idx) => {
            if (field.field_type === "sequential") {
              return (
                <div className={styles.textMetadataField}>
                  <div className={styles.sequentialLabelContainer}>
                    <span className={styles.metadataFieldLabelSequentials}>
                      {field.label}
                      <div className={styles.toolTipContainer}>
                        <div className={styles.annotIdTooltipBox}>
                          <Tooltip
                            type="description"
                            subType="sequentialSuppression"
                            variable={field.label}
                          />
                        </div>
                      </div>
                    </span>
                    <div className={styles.editActionButtons}>
                      <UikToggle
                        defaultChecked={!this.state.suppressSequential}
                        onChange={() => this.toggleSequentialSuppression()}
                      />
                    </div>
                  </div>
                  <span className={styles.metadataFieldValue}>
                    {this.renderEnhancedSequentialValue(field)}
                  </span>
                </div>
              );
            } else if (field.field_type === "input") {
              return (
                <div key={field.key} data-cy={field.key}>
                  <InputField
                    label={field.label}
                    name={field.key}
                    required={field.required}
                    values={this.state.documentFieldValues}
                    defaultValue={this.state.documentFieldValues[field.key]}
                  />
                </div>
              );
            } else if (
              field.field_type === "select_options" ||
              field.field_type === "multi_select_options"
            ) {
              return (
                <div>
                  <AudienceSelect
                    fieldType={field.field_type}
                    label={field.label}
                    name={field.key}
                    options={this.state.selectOptions[field.key]}
                    values={this.state.documentFieldValues}
                    defaultValue={this.state.documentFieldValues[field.key]}
                  />
                </div>
              );
            } else if (field.field_type === "date_time") {
              return (
                <div key={field.key} data-cy={field.key}>
                  <DateField
                    label={field.label}
                    name={field.key}
                    required={field.required}
                    values={this.state.documentFieldValues}
                    defaultValue={this.state.documentFieldValues[field.key]}
                    dateReminders={field.date_reminders}
                  />
                </div>
              );
            }
          })}
        </div>
        <div className={styles.metadataMultilineInputFields}>
          {this.state.documentFields.map((field, idx) => {
            if (field.field_type === "textarea") {
              return (
                <div key={field.key} data-cy={field.key}>
                  <TextareaField
                    label={field.label}
                    name={field.key}
                    required={field.required}
                    values={this.state.documentFieldValues}
                    defaultValue={this.state.documentFieldValues[field.key]}
                  />
                </div>
              );
            }
          })}
          <span className="uik-content-title__wrapper">Tags</span>
          <CreatableSelect
            isMulti
            onChange={this.handleChangeCreatableSelect}
            styles={customCreatableSelectStyles}
            options={this.state.availableTags}
          />
        </div>
      </div>
    );
  };

  handleChangeCreatableSelect = (newValue: any, actionMeta: any) => {
    let tagsList = [];

    if (newValue) {
      newValue.forEach((tag) => {
        tagsList.push(tag.value);
      });
    }

    this.setState({ tags_list: tagsList });
  };

  setSelectedTemplate = (template) => {
    if (!template) {
      this.setShowTemplatePreviewModal(false);
    }

    this.setState({
      selectedTemplate: template,
      templateUploadError: null,
    });
  };

  templateSelected = () => {
    const title = this.state.selectedTemplate.title;
    const token = security.getToken();

    const postConfig = {
      headers: {
        Authorization: "Bearer " + token,
      },
      onUploadProgress: (progressEvent) => {
        const mainDocumentPercent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (mainDocumentPercent >= 100) {
          this.setState({ mainDocumentPercent: 100 });
        } else {
          this.setState({ mainDocumentPercent });
        }
      },
    };

    const documentFormData = new FormData();
    documentFormData.append("document[title]", title);
    documentFormData.append(
      "document[library_id]",
      this.state.selectedLibrary.value
    );

    documentFormData.append(
      "document[document_template_id]",
      this.state.selectedTemplate.id
    );

    this.setState({
      mainDocumentPercent: 0,
      mainDocumentTitle: title,
      mainDocumentFilename: title,
    });

    axios
      .post(
        "/documents/create_from_template.json",
        documentFormData,
        postConfig
      )
      .then((response) => {
        this.setState({
          mainDocument: response.data,
          mainDocumentFileUploaded: true,
          error: false,
          errors: {},
          error_message: "",
        });
        httpClient
          .get(`/documents/${response.data.id}/metadata.json`)
          .then((response) => {
            let documentFieldValues = {};

            response.data.map((metadata, idx) => {
              documentFieldValues[metadata.document_field.key] = metadata.value;
            });

            this.setState({
              documentFieldValues: documentFieldValues,
            });
          });
      })
      .catch((error) => {
        security.checkAuth(error);
        console.log("error", error);

        notificationService.addNotification(
          "File upload failed",
          "File Failed to upload.",
          "warning"
        );
      });
  };

  goToDocument = (e) => {
    e.preventDefault();
    if (this.state.templateTabActive) {
      history.push({
        pathname: `/drafts/${this.state.mainDocument.id}`,
        state: {
          openTemplatePanel: true,
        },
      });
    } else {
      history.push(`/drafts/${this.state.mainDocument.id}`);
    }
  };

  goToDrafts = () => {
    history.push("/drafts");
  };

  saveTitle = (e) => {
    let documentFormData = {};

    documentFormData.title = this.state.mainDocumentTitle;

    httpClient
      .put(`/documents/${this.state.mainDocument.id}.json`, documentFormData)
      .then((response) => {
        this.setState({ mainDocument: response.data });

        documentFormData = {};
        documentFormData.tags_list = this.state.tags_list;

        httpClient
          .post(
            `/documents/${this.state.mainDocument.id}/tags.json`,
            documentFormData
          )
          .then((response) => {
            this.saveMetadata(e);
          });
      });
  };

  saveMetadata = (e) => {
    const documentFieldsData = {};

    this.state.documentFields.map((field, idx) => {
      if (field.field_type === "sequential") {
        documentFieldsData[field.key] = field.field_value;
      } else {
        documentFieldsData[field.key] =
          this.state.documentFieldValues[field.key];
      }
    });

    // Save the metadata values.
    httpClient
      .post(
        `/documents/${this.state.mainDocument.id}/document_field_values.json`,
        {
          suppress_sequential: this.state.suppressSequential,
          document_field_values: documentFieldsData,
        }
      )
      .then((response) => {
        // Only if successfully saved can we hit next.
        if (this.state.autoApprove) {
          history.push(`/documents/${this.state.mainDocument.id}`);
        } else {
          this.goToDocument(e);
        }
      });
  };

  handleNext = (e) => {
    if (this.state.autoApprove) {
      if (this.state.password) {
        const documentData = {
          user: {
            password: this.state.password,
          },
          document: {
            notes: this.state.notes,
            approved: this.state.autoApprove,
          },
          approval: {
            document_id: this.state.mainDocument.id,
            user_id: security.getUserId(),
            observer: false,
          },
        };
        httpClient
          .post(`/documents/auto_approve.json`, documentData)
          .then((response) => {
            this.setPasswordInvalid(false);
            this.saveTitle(e);
          })
          .catch((error) => {
            if (error) {
              this.setPasswordInvalid(true);
            }
          });
      } else {
        this.setPasswordAbsent(true);
      }
    } else {
      const documentFormData = {
        document: {
          library_id: this.state.selectedLibrary.value,
        },
      };
      httpClient
        .put(`/documents/${this.state.mainDocument.id}.json`, documentFormData)
        .then(() => {
          this.saveTitle(e);
        });
    }
  };

  render() {
    const { getFieldError, getFieldDecorator } = this.props.form;

    return (
      <div>
        <Container fluid className={styles.libraryDropdown}>
          <div className={styles.librarySelectTitle}>library</div>
          <LibrarySelect
            onLibraryChange={this.onLibraryChange}
            selectedLibrary={this.state.selectedLibrary}
          />
        </Container>
        {this.state.selectedLibrary && (
          <Container fluid className={styles.draftUploadForm}>
            {!this.state.mainDocument &&
              (this.state.currentUserRole === "author" ||
                this.state.currentUserRole === "admin") && (
                <div className={styles.tabsContainer}>
                  <div
                    className={
                      this.state.fileTabActive
                        ? styles.fileTabActive
                        : styles.fileTab
                    }
                    onClick={() => {
                      this.setFileTabActive();
                    }}
                  >
                    upload
                  </div>
                  <div
                    className={
                      this.state.templateTabActive
                        ? styles.templateTabActive
                        : styles.templateTab
                    }
                    onClick={() => {
                      this.setTemplateTabActive();
                    }}
                  >
                    build document
                  </div>
                </div>
              )}
            {this.state.fileTabActive && (
              <div className={styles.fileUploadContainer}>
                <div className={styles.formatNoteContainer}>
                  <div className={styles.uploadFileHeader}>file</div>
                  <p className={styles.uploadFileNote}>
                    We support PDF, Microsoft Office, images, videos and more.{" "}
                    <a
                      href="https://help.papercurve.com/knowledge-base/file-type-support"
                      target="_blank"
                      className={styles.kbLink}
                    >
                      See the full list
                      <img
                        className={styles.externalLink}
                        src={externalLinkIcon}
                      />
                    </a>
                  </p>
                </div>

                {this.state.selectedLibrary &&
                  !this.state.mainDocumentFileUploaded &&
                  this.state.mainDocumentPercent === null &&
                  this.renderMainDocumentUploader()}
              </div>
            )}
            {this.state.templateTabActive && !this.state.mainDocument && (
              <div className={styles.templateTabContainer}>
                <div
                  className={
                    this.state.templateUploadError
                      ? styles.templateUploadErrorState
                      : styles.templateUploadContainer
                  }
                >
                  <div className={styles.formatNoteContainer}>
                    <div className={styles.templateHeader}>
                      Upload or select a document
                    </div>
                    <p className={styles.uploadTemplateNote}>
                      We support Microsoft Office files. For instructions on
                      creating editable fields,{" "}
                      <a
                        href="https://help.papercurve.com/knowledge-base/document-generation-template-creation"
                        target="_blank"
                        className={styles.kbLink}
                      >
                        learn more
                        <img
                          className={styles.externalLink}
                          src={externalLinkIcon}
                        />
                      </a>
                    </p>
                  </div>
                  <div className={styles.contentRight}>
                    {this.renderTemplateUploader()}
                  </div>
                </div>
                {this.state.templateUploadError && (
                  <div className={styles.errorText}>
                    <img
                      className={styles.warningIconSmall}
                      src={warningSmall}
                    />
                    {this.state.templateUploadError}
                  </div>
                )}
                <DocumentTemplatesTable
                  setSelectedTemplate={this.setSelectedTemplate}
                  handleRenameTemplateModalOpen={
                    this.handleRenameTemplateModalOpen
                  }
                  handleDeleteTemplateModalOpen={
                    this.handleDeleteTemplateModalOpen
                  }
                  setShowTemplatePreviewModal={this.setShowTemplatePreviewModal}
                  selectedTemplate={this.state.selectedTemplate}
                />
              </div>
            )}
            {this.state.selectedTemplate &&
              this.state.showTemplatePreviewModal && (
                <TemplatePreviewModal
                  showTemplatePreviewModal={this.state.showTemplatePreviewModal}
                  setShowTemplatePreviewModal={this.setShowTemplatePreviewModal}
                  template={this.state.selectedTemplate}
                />
              )}
            <Modal
              onClickAway={this.handleCloseDeleteTemplateModal}
              visible={this.state.deleteTemplateModalOpen}
              width="540"
              height="240"
              effect="fadeInUp"
            >
              <div className={styles.renameContentContainer}>
                <div>
                  <h1 className={styles.renameContentHeadline}>
                    Confirm Delete
                  </h1>
                </div>
                <UikDivider />
                <div className={styles.renameContentFormContainer}>
                  Are you sure you want to delete this template?
                </div>
                <UikDivider />
                <div className={styles.renameContentFormButtons}>
                  <UikButton
                    className="floatRight"
                    onClick={() => this.handleDeleteTemplate()}
                    primary
                  >
                    Delete
                  </UikButton>
                  <UikButton
                    onClick={this.handleCloseDeleteTemplateModal}
                    className={[styles.renameCancelButton, "floatRight"]}
                  >
                    Cancel
                  </UikButton>
                </div>
              </div>
            </Modal>
            <Modal
              visible={this.state.renameTemplateModalOpen}
              width="540"
              height="270"
              effect="fadeInUp"
              onClickAway={(e) => this.handleCloseRenameTemplateModal(e)}
            >
              <div className={styles.renameContentContainer}>
                <div>
                  <h1 className={styles.renameContentHeadline}>
                    Rename Template
                  </h1>
                </div>
                <UikDivider />
                <div className={styles.renameContentFormContainer}>
                  <UikFormInputGroup>
                    {getFieldDecorator(`title`, {
                      initialValue: this.state.templateTitle,
                      rules: [
                        {
                          required: true,
                          message: "Please enter a title.",
                        },
                      ],
                    })(
                      <UikInput
                        label="TEMPLATE NAME"
                        placeholder="Template Title"
                      />
                    )}
                  </UikFormInputGroup>
                </div>
                <UikDivider />
                <div className={styles.renameContentFormButtons}>
                  <UikButton
                    className="floatRight"
                    onClick={(e) => this.handleRenameTemplate(e)}
                    primary
                  >
                    Save
                  </UikButton>
                  <UikButton
                    className={[styles.renameCancelButton, "floatRight"]}
                    onClick={(e) => this.handleCloseRenameTemplateModal(e)}
                  >
                    Cancel
                  </UikButton>
                  <div className="clear" />
                </div>
              </div>
            </Modal>
            <div className={styles.documentDetailsContainer}>
              {this.state.templateTabActive && this.state.mainDocument && (
                <div className={styles.templateHeading}> Template </div>
              )}
              {this.state.mainDocumentUploadError && (
                <div className={styles.mainDocumentUploadErrorContainer}>
                  <div className={styles.warningIcon}>
                    <img src={Warning} />
                  </div>
                  <div className={styles.errorMessage}>
                    File type not supported, please try another file.
                  </div>
                </div>
              )}

              {this.state.mainDocumentPercent !== null &&
                this.state.mainDocumentPercent >= 0 &&
                this.renderMainDocumentUploaderProgress()}

              {this.state.mainDocumentFileUploaded && (
                <div className={styles.documentDetails}>
                  <div className={styles.documentTitleHeader}>title</div>
                  <UikInput
                    onChange={(e) =>
                      this.setState({ mainDocumentTitle: e.target.value })
                    }
                    placeholder="Type the name of your content"
                    errorMessage={(getFieldError("title") || []).join(", ")}
                    value={this.state.mainDocumentTitle}
                    className={`${
                      this.state.mainDocumentTitle === ""
                        ? `${styles.titleInputFieldError}`
                        : ``
                    }`}
                  />
                  {this.state.mainDocumentTitle === "" && (
                    <div className={styles.emptyTitleError}>
                      Please enter a title.
                    </div>
                  )}
                  {this.renderDocumentFields()}
                </div>
              )}
            </div>
          </Container>
        )}
        {this.state.mainDocumentFileUploaded && (
          <Container fluid className={styles.autoApproveContainer}>
            <div className={styles.approvalToggleContainer}>
              <p className={styles.approvalToggleLabel}>
                Auto approve this content
                <div className={styles.toolTipContainer}>
                  <div className={styles.annotIdTooltipBox}>
                    <Tooltip type="description" subType="autoApprove" />
                  </div>
                </div>
              </p>
              <UikToggle
                defaultChecked={this.state.autoApprove}
                onChange={() => this.autoApproveContent()}
              />
              {this.state.autoApprove && (
                <div
                  className={`${styles.passwordConfirmation} ${
                    this.state.passwordInvalid || this.state.passwordAbsent
                      ? `${styles.passwordInvalid}`
                      : ``
                  }`}
                >
                  <div className={styles.confirmPasswordLabel}>
                    confirm password for approval
                  </div>
                  <UikInput
                    role="presentation"
                    autocomplete="off"
                    type="password"
                    value={this.state.password}
                    onChange={(e) => this.setPassword(e.target.value)}
                    onFocus={() => this.setPasswordInvalid(false)}
                  ></UikInput>
                  {this.state.passwordInvalid && (
                    <div className={styles.passwordInvalidLabel}>
                      Password is invalid
                    </div>
                  )}
                  {this.state.passwordAbsent && (
                    <div className={styles.passwordAbsentLabel}>
                      Enter password to continue
                    </div>
                  )}
                </div>
              )}
            </div>
          </Container>
        )}
        <Container fluid className={styles.draftUploadFormActions}>
          {this.state.mainDocument && this.state.mainDocumentFileUploaded && (
            <UikButton
              primary
              disabled={this.state.mainDocumentTitle === ""}
              onClick={(e) => this.handleNext(e)}
            >
              Next
            </UikButton>
          )}
          {this.state.templateTabActive && !this.state.mainDocument && (
            <UikButton
              className={styles.templateNext}
              primary
              disabled={
                this.state.mainDocumentTitle === "" ||
                !this.state.selectedTemplate
              }
              onClick={(e) => this.templateSelected(e)}
            >
              Next
            </UikButton>
          )}
          {!this.state.mainDocument && this.state.mainDocumentFileUploaded && (
            <div className={styles.processingContainer}>
              <Spinner text={"Processing..."} />
            </div>
          )}
        </Container>
      </div>
    );
  }
}

const DraftUploadForm = createForm()(DraftUploadFormBuild);

const mapStateToProps = (state) => {
  return {
    documentTemplates: state.documentTemplates,
  };
};

const actionCreators = {
  fetchDocumentTemplates,
};

export default connect(mapStateToProps, actionCreators)(DraftUploadForm);
